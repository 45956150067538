import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import Layout from './pages/Layout';
import * as routes from './routes';

import { ErrorHandlerProvider } from './providers/ErrorManagerContext';
import { LanguageProvider } from './providers/LanguageContext';
import { SettingsProvider, EnsureLoggedInRoutes } from './providers/SettingsContext';
import { DialogProvider } from './providers/Dialogs';

// Pages
import Home from './pages/Home';
import Publication from './pages/Publication';
import PublicationUpload from './pages/PublicationUpload';
import Reference from './pages/Reference';
import Adjustments from './pages/Adjustments';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<PageContext />}>
					<Route path={routes.home} element={<Home />} />
					<Route element={<EnsureLoggedInRoutes />}>
						<Route path={routes.publication(':id')} element={<Publication />} />
						<Route path={routes.publication_upload} element={<PublicationUpload />} />
						<Route path={routes.reference(':pubId', ':itemNumber')} element={<Reference />} />
						<Route path={routes.adjustment} element={<Adjustments />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

function PageContext() {
	return (
		<SettingsProvider>
			<LanguageProvider>
				<ErrorHandlerProvider>
					<DialogProvider>
						<Layout>
							<Outlet />
						</Layout>
					</DialogProvider>
				</ErrorHandlerProvider>
			</LanguageProvider>
		</SettingsProvider>
	)
}

export default App;
