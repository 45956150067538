import Log from "./logger";
import { Adjustment } from "./entities";

export abstract class AdjustmentsStore {
	public abstract get(p: {
		log: Log,
		itemNumber?: string,
	}): Promise<Adjustment[]>;
	public abstract save(p: {
		log: Log,
		itemNumber: string,
		adjustment: Adjustment,
	}): Promise<Adjustment>;
	public abstract delete(p: {
		log: Log,
		itemNumber: string,
	}): Promise<Adjustment | null>;
}

export default AdjustmentsStore;
