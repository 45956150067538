import React, { CSSProperties, useState } from 'react';
import css from './Tooltip.module.css';

function Tooltip({ children, content, direction = 'top', className, style }: {
	children: React.ReactNode,
	content: React.ReactNode,
	direction?: 'top' | 'bottom' | 'right',
	className?: string,
	style?: CSSProperties,
}) {
	const [isVisible, setIsVisible] = useState(false);

	const directionStyle = {
		'top': css.top,
		'bottom': css.bottom,
		'right': css.right,
	}[direction];

	return (
		<span
			className={`${css.container} ${(className != null) ? className : ''}`}
			style={style}
			onMouseEnter={() => setIsVisible(true)}
			onMouseLeave={() => setIsVisible(false)}
		>
			{children}
			{isVisible && <div className={`${css.box} ${directionStyle}`}>{content}</div>}
		</span>
	);
};

export default Tooltip;
