import { strEnum } from "./utils";
import { SubGroup } from "./groups";

export const { e: Languages, a: allLanguages } = strEnum(['fr', 'nl']);
export type Language = keyof typeof Languages;
export type TranslatedItem<T> = {
	[key in Language]: T;
}
export type TranslatedString = TranslatedItem<string>;

export const languageNames: TranslatedString = {
	fr: 'Français',
	nl: 'Nederlands',
};

export const { e: AccessRights, a: allAccessRights } = strEnum([
	'tenants_use',
	'tenants_manageall',
	'publication_get',
	'publication_manage',
	'reference_get',
	'reference_manage',
	'adjustment_get',
	'adjustment_manage',
]);
export type AccessRight = keyof typeof AccessRights;

export interface Settings {
	loggedIn: boolean;
	priviledges: AccessRight[];
	tenant: string | null;
	availableTenants: string[];

	currentPublication: null | {
		id: number;
		date: Date;
	};
}

export interface Publication {
	publicationId: number;  // eg. '20231231235959'
	prevId?: number | null;
	date: string;
	fileIds: string[];
}

export interface Reference {
	itemNumber: string;
	publicationId: number;  // eg. '20231231235959'
	prevItemNumber?: string | null;

	name: { [lang in Language]?: string };
	price: number;

	subGroup?: SubGroup | null;
}

export interface Adjustment {
	itemNumber: string,
	validatedForPublicationId: number;

	adjustedPrice: number;
	validUntil: string;
}

export interface ApiResponse {
	success?: true;
	error?: string;
	log?: string[];
}
