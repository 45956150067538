import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import * as utils from "../utils";
import * as routes from "../routes";
import * as icons from "../media/icons";
import { useSettings } from "../providers/SettingsContext";
import { useDialog } from "../providers/Dialogs";
import Translatable from "../components/Translatable";
import LanguageSelection from "../components/LanguageSelection";

// load bootstrap theme:
import 'bootstrap/dist/css/bootstrap.min.css';

const LayoutContext = createContext<{
	headerPlaceholder: null | JSX.Element,
	setHeaderPlaceholder: (v: null | JSX.Element) => void,
	isDarkMode: boolean,
	setIsDarkMode: (v: boolean) => void,
	breakPoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',  // cf. bootstrap breakpoints
	isMobile: boolean,
}>(null!);
export function useLayout() {
	return useContext(LayoutContext) ?? utils.throwError('useLayout must be used within a LayoutProvider');
}

function Layout() {
	const { headerPlaceholder, breakPoint, isDarkMode } = useLayout();
	const sidePanelSize = '280px';

	// watch for light/dark theme switch
	useEffect(() => {
		// set <body>'s class
		document.body.className = isDarkMode ? 'bg-black text-white' : 'bg-light';
		document.body.setAttribute('data-bs-theme', (isDarkMode ? 'dark' : 'light'));

		// update storage according to user's choice
		getIsDarkMode({ state: isDarkMode });
	}, [isDarkMode]);

	return <>
		<div  // left sidebar
			className={`float-start sticky-top min-vh-100 d-none d-${breakPoint}-block bg-body-secondary shadow`}  // invisible when < breakPoint
			style={{ width: sidePanelSize }}
		>
			<Link  // sidebar | logo
				to={routes.home}
			>
				<Container
					className="text-center mt-3 mb-5"
				>
					<icons.Logo />
				</Container>
			</Link>

			<Container  // sidebar | top
			>
				<Navbar variant={isDarkMode ? 'dark' : 'light'}>

					<MenuA  // list of routes
						className="flex-column"
					/>

				</Navbar>
			</Container>

			<div  // sidebar | bottom
				className="fixed-bottom"
				style={{ width: sidePanelSize }}
			>
				<Container>
					<Navbar variant={isDarkMode ? 'dark' : 'light'}>

						<MenuB  // language/theme buttons
							className="flex-column"
						/>

					</Navbar>
				</Container>
			</div>
		</div>

		<div  // main
			className="d-flex m-0"
		>
			<Container
				fluid
				className="p-0"
			>

				<Navbar  // top menu
					expand={breakPoint}
					variant={isDarkMode ? 'dark' : 'light'}
					className="sticky-top bg-body-secondary border-bottom"
				>
					<Container
						fluid
						className={`ps-${breakPoint}-0`}  // remove left-padding in non-mobile (ie. the sidebar is on the left => need to perfectly align with sidebar)
					>

						{headerPlaceholder ??
							<Navbar.Brand  // nb: visible only if 'headerPlaceholder' is 'null'
								as={NavLink}
								to={routes.home}
							>
								<icons.Logo
									className={`d-${breakPoint}-none me-3`}  // visible when < breakPoint
									style={{ height: '1.5em' }}
								/>
								SparePrice
							</Navbar.Brand>
						}

						<Navbar.Toggle  // burger button
						/>
						<Navbar.Collapse  // top menu elements / drop-down content
						>
							<hr />

							<MenuA  // list of routes
								className={`d-${breakPoint}-none`}  // visible when < breakPoint
							/>

							<hr />

							<MenuB  // language/theme buttons
								className={`d-${breakPoint}-none`}  // visible when < breakPoint
							/>
						</Navbar.Collapse>

					</Container>
				</Navbar>

				<Outlet  // page body
				/>

			</Container>
		</div>
	</>
}

/** list of routes */
function MenuA({ className }: {
	className?: string,
}) {
	const { settings, hasAccess } = useSettings();

	const currentPublicationPath = (settings.currentPublication != null)
		? routes.publication(settings.currentPublication.id)
		: null;

	return <>
		<Nav className={className}>

			<Nav.Item>
				<Nav.Link as={NavLink} to={routes.home}>
					<icons.Home /> Home
				</Nav.Link>
			</Nav.Item>

			{(hasAccess('publication_get')) && (currentPublicationPath != null) &&
				<Nav.Item>
					<Nav.Link as={NavLink} to={currentPublicationPath}>
						<icons.PageLinkOther />{' '}
						<Translatable>{{
							fr: 'Publication actuelle',
							nl: 'Huidige publicatie',
						}}</Translatable>
					</Nav.Link>
				</Nav.Item>
			}

			{hasAccess('publication_manage') &&
				<Nav.Item>
					<Nav.Link as={NavLink} to={routes.publication_upload}>
						<icons.PageLinkOther />{' '}
						<Translatable>{{
							fr: 'Téléverser une publication',
							nl: 'Publicatiebestand uploaden',
						}}</Translatable>
					</Nav.Link>
				</Nav.Item>
			}

			{hasAccess('adjustment_get') &&
				<Nav.Item>
					<Nav.Link as={NavLink} to={routes.adjustment}>
						<icons.PageLinkOther />{' '}
						<Translatable>{{
							fr: 'Ajustements',
							nl: 'Aanpassingen',
						}}</Translatable>
					</Nav.Link>
				</Nav.Item>
			}

		</Nav>
	</>
}

/** logout/language/theme buttons */
function MenuB({ className }: {
	className?: string,
}) {
	const { newLog, settings, logout } = useSettings();
	const { confirm } = useDialog();
	const { isDarkMode, setIsDarkMode, isMobile } = useLayout();

	async function handleLogout(confirmed: boolean = false) {
		if (!confirmed) {
			if (await (confirm({ message: { fr: 'Se déconnecter?', nl: 'Afmelden?' } })))
				handleLogout(true);
			return;
		}
		await logout(newLog('logout'));
	}

	return <>
		<Nav className={className}>

			<Nav.Link as="span">
				<Translatable>{{
					fr: 'Langue:',
					nl: 'Taal:',
				}}</Translatable>
				<LanguageSelection
					setGlobal={true}
					className="d-inline-block"
					drop={isMobile ? undefined : 'up'}  // HACK: workaround for menu appearing below viewport ; cf. https://github.com/react-bootstrap/react-bootstrap/issues/6724
				/>
			</Nav.Link>

			<Nav.Link onClick={() => setIsDarkMode(!isDarkMode)}>
				Theme:&nbsp;
				<span style={{ verticalAlign: 'text-bottom' }}>
					{isDarkMode ? <icons.ModeDark size='0.9em' /> : <icons.ModeLight size='0.9em' />}
				</span>
			</Nav.Link>

			{settings.loggedIn &&
				<Nav.Link onClick={() => handleLogout()}>
					Logout&nbsp;
					<span style={{ verticalAlign: 'text-bottom' }}>
						<icons.Logout />
					</span>
				</Nav.Link>}

		</Nav >
	</>
}

/** returns 'true' if currently using "dark" theme */
function getIsDarkMode({ state }: { state: boolean | null }) {
	const storageKey = 'darkMode';
	const browser = window.matchMedia('(prefers-color-scheme: dark)').matches;
	const storageStr = localStorage.getItem(storageKey);
	const storage = (storageStr == null) ? null : (storageStr === 'true') ? true : false;

	const expected = state ?? storage ?? browser;

	if (expected === browser) {
		// equals default => no need to keep the storage variable
		if (storage != null)
			localStorage.removeItem(storageKey);
	} else {
		// need to save the user's choice in the local storage
		if ((storage == null) || (storage !== expected))
			localStorage.setItem(storageKey, expected ? 'true' : 'false');
	}

	return expected;
}

/** returns 'true' if < bootstrap breakPoint */
function getIsMobile(breakPoint: string) {
	const breakpointProperty = getComputedStyle(document.body).getPropertyValue(`--bs-breakpoint-${breakPoint}`).trim();
	const breakpointValue = parseInt(breakpointProperty, 10);
	const innerWidth = window.innerWidth;
	return (innerWidth < breakpointValue);
}

function LayoutProvider(_: {
	children: ReactNode,
}) {
	const breakPoint = 'lg';  // cf. bootstrap breakpoints
	const [headerPlaceholder, setHeaderPlaceholder] = useState<JSX.Element | null>(null);
	const [isDarkMode, setIsDarkMode] = useState(() => getIsDarkMode({ state: null }));
	const [isMobile, setIsMobile] = useState(() => getIsMobile(breakPoint));

	// watch for window resize
	useEffect(() => {
		const handleResize = () => {
			// update 'isMobile' state
			setIsMobile(getIsMobile(breakPoint));
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<LayoutContext.Provider value={{
			headerPlaceholder,
			setHeaderPlaceholder,
			isDarkMode,
			setIsDarkMode,
			breakPoint,
			isMobile,
		}}>
			<Layout />
		</LayoutContext.Provider>
	);
}

export default LayoutProvider;
