import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as icons from '../media/icons';
import Translatable from './Translatable';

const BackButton: React.FC = () => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return <>
		<button type="button" onClick={goBack} className="btn btn-secondary">
			<icons.Back />
			<Translatable>{{
				fr: ' Retour',
				nl: ' Terug',
			}}</Translatable>
		</button>
	</>
};

export default BackButton;
