import React, { createContext, useState, useContext, ReactNode } from 'react';

import * as utils from '../utils';
import { Language, TranslatedItem } from '../common/entities';

const defaultLanguage: Language = 'fr';

const LanguageContext = createContext<{
	language: Language;
	setLanguage: (language: Language) => void;
	translate: (trs: TranslatedItem<string>) => string;
}>(null!);

export const useLanguage = () => useContext(LanguageContext) ?? utils.throwError(`'useLanguage()' must be used within a 'LanguageProvider'`);

export function LanguageProvider({ children }: {
	children: ReactNode,
}) {
	const [language, setLanguage] = useState<Language>(defaultLanguage); // Default language
	const translate = (trs: TranslatedItem<string>) => trs[language];
var HERE = 123;//Get default language from browser config & save in browser's session somehow

	return (
		<LanguageContext.Provider value={{ language, setLanguage, translate }}>
			{children}
		</LanguageContext.Provider>
	);
}
