import { useEffect, useState } from "react";
import { Tree, TreeItem, ControlledTreeEnvironment } from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css';

import { generateId } from "../common/utils";
import { TranslatedString } from "../common/entities";
import * as grp from "../common/groups";
import * as fltr from "../common/itemsFilters";

import { useLanguage } from "../providers/LanguageContext"

type GroupOrSub = grp.Group | grp.SubGroup;

export function GroupsTree({ filter }: {
	filter: fltr.FilterBase,
}) {
	const { language } = useLanguage();
	const [focusedItem, setFocusedItem] = useState<string>();
	const [expandedItems, setExpandedItems] = useState<string[]>([]);
	const [s] = useState({
		idTree: generateId(),
	});

	// initial state
	useEffect(() => {
		// expand all groups that have subGroups selected
		const expanded = new Set<grp.Group>();
		Array.from(filter.groups).forEach(v => {
			if (!grp.allSubGroupsSet.has(v as grp.SubGroup))
				// not a subgroup
				return;
			expanded.add(grp.getGroup(v)!);
		});
		setExpandedItems(Array.from(expanded));
	}, [filter]);

	// cf. https://rct.lukasbach.com/docs/getstarted/
	const treeItems: { [k: string]: TreeItem<string> } = {
		root: {
			index: 'root',
			children: ['selectAll'],
			data: 'root',
			isFolder: true,
			canRename: false,
		},
		selectAll: {
			index: 'selectAll',
			data: ({ fr: 'Tous', nl: 'Alles' } as TranslatedString)[language],
			isFolder: false,
			canRename: false,
		},
	};
	const rootNode = treeItems['root'];
	// Add groups
	grp.allGroups.sort().forEach((group) => {
		rootNode.children!.push(group);
		treeItems[group] = {
			index: group,
			children: [],
			data: `${group}: ${grp.getGroupName(group, language)}`,
			isFolder: true,
			canRename: false,
		};
	});
	// Add subgroups
	grp.allSubGroups.sort().forEach((subGroup) => {
		const group = grp.getGroup(subGroup)!;
		treeItems[group].children!.push(subGroup);
		treeItems[subGroup] = {
			index: subGroup,
			data: `${subGroup}: ${grp.getSubGroupName(subGroup, language)}`,
			isFolder: false,
			canRename: false,
		};
	});

	const selectedItems = (filter.groups.size === 0)
		? ['selectAll']
		: Array.from(filter.groups);

	return <>
		<ControlledTreeEnvironment
			items={treeItems}
			getItemTitle={item => item.data}
			viewState={{
				[s.idTree]: {
					focusedItem,
					expandedItems,
					selectedItems,
				},
			}}
			onFocusItem={item => setFocusedItem(item.index as string)}
			onExpandItem={item => setExpandedItems([...expandedItems, item.index as string])}
			onCollapseItem={item =>
				setExpandedItems(expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index))
			}
			onSelectItems={(items) => filter.apply((f) => {
				f.groups.clear();
				items.forEach(item => f.groups.add(item as GroupOrSub));
			})}
		>
			<Tree treeId={s.idTree} rootItem="root" treeLabel="Groups tree" />
		</ControlledTreeEnvironment>
	</>
}
