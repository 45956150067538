import Log from "./logger";
import { dateString, strEnum, throwError } from "./utils";
import { Adjustment, Language, Reference, TranslatedItem, allLanguages } from "./entities";
import PublicationsStore from "./publicationsStore";
import ReferencesStore from "./referencesStore";
import AdjustmentsStore from "./adjustmentsStore";

const { e: RuleErrors } = strEnum([
	// For references:
	'noPrice',
	'noGroup',
	'noTranslation',

	// For adjustments:
	'priceChanged',
	'expired',
]);
type RuleError = keyof typeof RuleErrors;

export const ruleErrorTexts: {
	[key in RuleError]: TranslatedItem<string>;
} = {
	// For references:
	'noTranslation': {
		'fr': 'Nom de produit manquant',
		'nl': 'Productnaam ontbreekt',
	},
	'noPrice': {
		'fr': 'Prix manquant',
		'nl': 'Prijs ontbreekt',
	},
	'noGroup': {
		'fr': 'Groupe manquant',
		'nl': 'Groep ontbreekt',
	},

	// For adjustments:
	'priceChanged': {
		'fr': 'Le prix à changé depuis l\'ajustement',
		'nl': 'De prijs is veranderd sinds de aanpassing',
	},
	'expired': {
		'fr': 'Expiré',
		'nl': 'Verlopen',
	},
}

/** Number of days after which adjustments expire */
export const expirationDaysDefault = 90;
export function getExpirationDateDefault() {
	const today = new Date();
	const d = new Date(today.getTime() + (expirationDaysDefault * 24 * 60 * 60 * 1000));
	return dateString({ d, withTime: false });
}

export class ItemState {
	public readonly errors: RuleError[] = [];
	constructor(
		public readonly item: Reference,
	) { }

	public hasErrors() {
		return this.errors.length > 0;
	}

	public getErrorTexts(l: Language) {
		return this.errors.map(v => ruleErrorTexts[v][l]);
	}
}

export class ReferenceState extends ItemState {
	constructor(
		ref: Reference,
	) {
		super(ref);
		this.init()
	}

	private init() {
		for (const lang of allLanguages) {
			if ((this.item.name[lang] ?? '').length === 0) {
				this.errors.push('noTranslation');
				break;
			}
		}

		if ((this.item.price <= 0) && (this.item.price !== -1))
			this.errors.push('noPrice');

		if (this.item.subGroup == null)
			this.errors.push('noGroup');

		return this;
	}
}

export class AdjustmentState extends ItemState {
	constructor(
		reference: Reference,
		public readonly adjustment: Adjustment | null,
		public readonly validatedForReference: Reference | null,
	) {
		super(reference);
		this.init();
	}

	private init() {
		const adjustment = this.adjustment;
		if (adjustment == null)
			// No adjustments on that reference => no rule to apply
			return this;
		const reference = this.item;
		const validatedFor = this.validatedForReference ?? throwError(`'AdjustmentState.validatedFor' is not set but 'adjustement' is`);  // Both are supposed to be set together

		if (validatedFor.price !== reference.price)
			this.errors.push('priceChanged');

		const dtNow = dateString({ d: new Date(), withTime: false });
		const dtAdj = adjustment.validUntil;
		if (dtNow >= dtAdj)
			this.errors.push('expired');

		return this;
	}

	public static async fetch(log: Log, publicationId: number, pubSrc: PublicationsStore, refSrc: ReferencesStore, adjSrc: AdjustmentsStore) {
		// Fetch publication history, references, adjustments
		const tasks = {
			pubs: pubSrc.get({ log: log.child('pubs'), publicationId, recursive: true }),
			refs: refSrc.get({ log: log.child('refs'), publicationId }),
			adjs: adjSrc.get({ log: log.child('adjs') }),
		};
		const { publications, references, adjustments } = { publications: await tasks.pubs, references: await tasks.refs, adjustments: await tasks.adjs };
		if (publications.length === 0)
			throwError(`Publication ${publicationId} not found`, log);

		// Create publication IDs history
		const publicationIds = publications.map(v => v.publicationId);

		// Map references <-> adjustments
		const pairs = await Promise.all(
			(adjustments).map(
				(adjustment) => this.findAdjustmentReference(log, publicationIds, adjustment, references, refSrc)
			)
		);

		const states = references.map((reference) => {
			const pair = pairs.find(v => v.current?.itemNumber === reference.itemNumber);
			return new AdjustmentState(reference, pair?.adjustment ?? null, pair?.validatedFor ?? null);
		});

		return { states };
	}

	private static async findAdjustmentReference(
		log: Log,
		publicationIDs: number[],
		adjustment: Adjustment,
		references: Reference[],
		refSrc: ReferencesStore,
	): Promise<{
		adjustment: Adjustment,
		current: Reference | null,
		validatedFor: Reference | null,
	}> {
		// Try find current reference for this 'itemNumber'
		const current = references.find((v) => v.itemNumber === adjustment.itemNumber) ?? null;
		if ((current != null) && (adjustment.validatedForPublicationId === current.publicationId))
			// Already validated for current publication => 'current' is same as 'validatedFor'
			return { adjustment, current, validatedFor: current };

		// Retreive the 'validatedFor' reference
		const validatedFor = (
			await refSrc.get({
				log: log.child('validatedFor'),
				publicationId: adjustment.validatedForPublicationId,
				itemNumber: adjustment.itemNumber,
			})
		)[0];
		if (validatedFor == null) {
			log.warning(`Could not find reference price of item '${adjustment.itemNumber}' for publication '${adjustment.validatedForPublicationId}'`);
			return { adjustment, current, validatedFor: null };
		}

		if (current != null)
			// itemNumber did not change => just retreive the 'validatedFor'
			return { adjustment, current, validatedFor };

		// 'itemNumber' has changed => find its new value in the publication history
		let itemNumberToFind = adjustment.itemNumber;
		for (let idx = publicationIDs.indexOf(adjustment.validatedForPublicationId) - 1; idx >= 0; --idx) {
			const prevPubId = publicationIDs[idx];
			const prevRef = (
				await refSrc.get({ log: log.child('search'), publicationId: prevPubId, prevItemNumber: itemNumberToFind })
			)[0];
			if (prevRef == null)
				// Not found
				break;

			const current = references.find((v) => v.itemNumber === prevRef.itemNumber);
			if (current != null)
				// Found !
				return { adjustment, current, validatedFor };

			itemNumberToFind = prevRef.itemNumber;  // nb: itemNumber may have changed more than once since initial save => need to track all potential changes
		}
		// Not found
		log.warning('Reference not found for adjustment', { adjustment });
		return { adjustment, current: null, validatedFor };
	}
}
