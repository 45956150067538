import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Table from "react-bootstrap/Table";

import * as routes from '../routes';
import { Language } from "../common/entities";
import { getGroupName, getSubGroupName } from "../common/groups";
import { ItemState } from "../common/itemRules";
import { FilterBase, ReferencesFilter } from "../common/itemsFilters";

import { useLanguage } from "../providers/LanguageContext";

import * as utils from "../utils";
import { InputText } from "./inputs";
import * as icons from '../media/icons';
import { GroupsTree } from "./GroupsSelection";
import Translatable from "./Translatable";
import Tooltip from "./Tooltip";
import * as fltr from "../common/itemsFilters";

export type ColumnType =
	'group' |
	'itemNumber' |
	'name' |
	'price';

/** References table */
export function ReferencesTable({ filter }: {
	filter: ReferencesFilter,
}) {
	const { language } = useLanguage();

	const lines = filter.filteredRules;

	return <>
		<Paging filter={filter} />

		<Table striped hover className="table-sm">
			<thead>
				<tr>
					<CellHeader type="itemNumber" style={{ width: '1%' }} />
					<CellHeader type="name" />
					<CellHeader type="price" style={{ width: '1%' }} />
					<CellHeader type="group" />
				</tr>
				<tr>
					<CellFilter type="itemNumber" filter={filter} />
					<CellFilter type="name" filter={filter} />
					<CellFilter type="price" filter={filter} />
					<CellFilter type="group" filter={filter} />
				</tr>
			</thead>
			<tbody>
				{lines.map((line) =>
					<tr key={line.item.itemNumber} className={line.hasErrors() ? 'table-danger' : ''}>
						<CellContent type="itemNumber" language={language} state={line} />
						<CellContent type="name" language={language} state={line} />
						<CellContent type="price" language={language} state={line} />
						<CellContent type="group" language={language} state={line} />
					</tr>
				)}
			</tbody>
		</Table>

		<Paging filter={filter} />
	</>
}

export function CellHeader({ type, style, colSpan }: {
	type: ColumnType,
	style?: CSSProperties,
	colSpan?: number,
}) {
	switch (type) {
		case 'itemNumber': return (
			<th scope="col" style={{ textAlign: 'right', ...style }} colSpan={colSpan}>
				#
			</th>);
		case 'name': return (
			<th scope="col" style={style} colSpan={colSpan}>
				<Translatable>{{
					'fr': 'Nom',
					'nl': 'Naam',
				}}</Translatable>
			</th>);
		case 'group': return (
			<th scope="col" style={style} colSpan={colSpan}>
				<Translatable>{{
					'fr': 'Groupe',
					'nl': 'Groep',
				}}</Translatable>
			</th>);
		case 'price': return (
			<th scope="col" style={{ textAlign: 'right', ...style }} colSpan={colSpan}>
				<Translatable>{{
					'fr': 'Prix',
					'nl': 'Prijs',
				}}</Translatable>
			</th>);
		default: utils.throwError(`Unknown column type '${type}'`);
	}
}

export function CellFilter({ type, filter, style, colSpan }: {
	type: ColumnType,
	filter: fltr.FilterBase,
	style?: CSSProperties,
	colSpan?: number,
}) {
	switch (type) {
		case 'itemNumber': return (
			<th scope="col"
				style={style}
				className="p-0"
				colSpan={colSpan}
			>
				<InputText
					className="form-control"
					style={{ minWidth: '4.5em' }}
					initialValue={filter.itemNumber}
					onChange={(v) => filter.apply((f) => f.itemNumber = v)}
				/>
			</th>
		);
		case 'name': return (
			<th scope="col"
				style={style}
				className="p-0"
				colSpan={colSpan}
			>
				<InputText
					className="form-control"
					initialValue={filter.name}
					onChange={(v) => filter.apply((f) => f.name = v)}
				/>
			</th>
		);
		case 'group': return (
			<Dropdown as="th"
				scope="col"
				style={style}
				className="p-0"
				colSpan={colSpan}
			>
				<Dropdown.Toggle as="input"
					value={Array.from(filter.groups).sort().join(', ')}
					className="form-control"
					readOnly
				/>
				<Dropdown.Menu>
					<GroupsTree filter={filter} />
				</Dropdown.Menu>
			</Dropdown>
		);
		default: return (  // unsupported
			<th scope="col" style={style} colSpan={colSpan} />
		);
	}
}

export function CellContent({ type, state, language, style, colSpan }: {
	type: ColumnType,
	state: ItemState,
	language: Language,
	style?: CSSProperties,
	colSpan?: number,
}) {
	switch (type) {
		case 'itemNumber': return (
			<td style={style} colSpan={colSpan}>
				{state.hasErrors() &&
					<Tooltip direction='right' content={state.getErrorTexts(language).map((v) => <div key={v}>{v}</div>)}>
						<icons.Error size="1em" style={{ color: 'red' }} />
					</Tooltip>}
				<Link to={routes.reference(state.item.publicationId, state.item.itemNumber)} tabIndex={-1}>
					<div style={{ float: 'right' }}>
						{state.item.itemNumber}
					</div>
				</Link>
			</td>);
		case 'name': return (
			<td style={style} colSpan={colSpan}>
				<Link to={routes.reference(state.item.publicationId, state.item.itemNumber)} tabIndex={-1}>
					{state.item.name[language]}
				</Link>
			</td>);
		case 'group': {
			const group = getGroupName(state.item.subGroup, language)
			const subGroup = getSubGroupName(state.item.subGroup, language)!;
			return (
				<td style={style} colSpan={colSpan}>
					{(group == null)
						? <icons.Missing />
						: `${state.item.subGroup}: ${group} | ${subGroup}`
					}
				</td>)
		};
		case 'price': return (
			<td style={style} colSpan={colSpan} className="text-end">
				{state.item.price}
			</td>);
		default: utils.throwError(`Unknown column type '${type}'`);
	}
}

export function Paging({ filter }: {
	filter: FilterBase,
}) {
	const [page, setPage] = useState(filter.page);
	const [pageSize, setPageSize] = useState(filter.pageSize);

	// Reset page textbox when filter is refreshed
	useEffect(() => {
		setPage(filter.page);
		setPageSize(filter.pageSize);
	}, [filter.page, filter.pageSize]);

	function gotoPage(e: React.MouseEvent, n: number) {
		e.preventDefault();
		if (isNaN(n))
			n = 0;
		filter.apply(f => f.page = n);
	}

	return (
		<Pagination>
			<Pagination.First onClick={(e) => gotoPage(e, 0)} />
			<Pagination.Prev onClick={(e) => gotoPage(e, filter.page - 1)} />

			<li className="page-item">
				<input type="number" className="page-link" style={{ width: '4.5em', textAlign: 'right' }}
					value={page + 1}
					min={1}
					max={filter.totalPages}
					onChange={(e) => setPage(parseInt(e.target.value) - 1)}
					onBlur={() => filter.apply(f => f.page = page)}
					onKeyDown={(e) => { if (e.key === 'Enter') filter.apply(f => f.page = page); }}
				/>
			</li>
			<Pagination.Item disabled>/ {filter.totalPages}</Pagination.Item>

			<Pagination.Next onClick={(e) => gotoPage(e, filter.page + 1)} />
			<Pagination.Last onClick={(e) => gotoPage(e, filter.totalPages)} />

			<Pagination.Item disabled>
				<Translatable>{{
					fr: 'Par page:',
					nl: 'Per pagina:',
				}}</Translatable>
			</Pagination.Item>
			<li className="page-item">
				<input type="number" className="page-link" style={{ width: '5em', textAlign: 'left' }}
					value={pageSize}
					min={1}
					max={filter.allItems.length}
					onChange={(e) => setPageSize(parseInt(e.target.value))}
					onBlur={() => filter.apply(f => f.pageSize = pageSize)}
					onKeyDown={(e) => { if (e.key === 'Enter') filter.apply(f => f.pageSize = pageSize); }}
				/>
			</li>
		</Pagination>
	)
}

export default ReferencesTable;
