import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import * as routes from "../routes";
import Log from '../common/logger';
import * as utils from '../common/utils';
import { AccessRight, Settings } from "../common/entities";

import * as icons from '../media/icons';

const SettingsContext = createContext<null | {
	settings: Settings,
	hasAccess: (ar: AccessRight) => boolean,
	refreshSettings: () => Promise<Settings>,
	newLog: (name: string) => Log,
	logout: (log: Log) => Promise<void>,
}>(null);

export const useSettings = () => useContext(SettingsContext) ?? utils.throwError(`'useSettings()' must be used within a 'SettingsProvider'`);

export function SettingsProvider({ children }: {
	children: ReactNode,
}) {
	const [settings, setSettings] = useState<null | 'error' | Settings>(null);

	useEffect(() => {
		(async () => {
			const log = newLog('settings');
			try {
				log.log('Launch initial fetch');
				setSettings(await fetchSettings(log));
			} catch (ex) {
				log.error('Fetch settings failed');
				log.exception(ex);
				setSettings('error');
			}
		})();
	}, []);

	if (settings == null)
		return <icons.LoadingSpinnerPage />
	if (settings === 'error')
		return <icons.LoadingError />

	const hasAccess = (ar: AccessRight) => {
		return settings.priviledges.includes(ar) ?? false;
	}

	async function refreshSettings() {
		const log = newLog('settings');
		let s = null as Settings | null;
		try {
			log.log('Refresh settings');
			s = await fetchSettings(log);
			setSettings(s);
			return s;
		} finally {
			if (s == null)
				log.error('Fetch settings failed');
		}
	}

	const logout = async (log: Log) => {
		log.log(`query logout endpoint`);  // to remove 'auth' cookie
		const { logoutFlowUrl } = await utils.apiRequest<routes.api.general.LogoutResult>({
			method: 'GET',
			url: routes.api.general.logout(),
			exceptionMessage: 'Logout server request failed',
		});

		log.log(`refresh setting's context`);
		await refreshSettings();

		log.log(`go to '${logoutFlowUrl ?? '<null>'}'`);  // to logout from oid provider
		window.location.href = logoutFlowUrl;
	}

	return (
		<SettingsContext.Provider value={{
			settings: settings,
			hasAccess,
			refreshSettings,
			newLog,
			logout,
		}}>
			{children}
		</SettingsContext.Provider>
	);
}

export function EnsureLoggedInRoutes() {
	const { settings } = useSettings();

	if (!settings.loggedIn) {
		// Redirect to login page
		const pathAndQuery = `${window.location.pathname}${window.location.search}${window.location.hash}`;
		window.location.href = routes.api.general.login(pathAndQuery);
		return <icons.LoadingSpinnerPage />;
	}

	return <Outlet />;
}

function newLog(name: string) {
	return new Log(name, undefined, (self, date, message) => {
		console.log(self.getLineString(date, message));
	});
}

async function fetchSettings(log: Log) {
	log.log('Fetch settings');
	let settings = await utils.apiRequest<routes.api.general.SettingsResult>({
		url: routes.api.general.settings(),
		exceptionMessage: 'Unable to retreive application settings from server',
	});

	if (settings?.currentPublication?.date != null)
		settings.currentPublication.date = new Date(settings.currentPublication.date);  // nb: come as ISO strings from server

	settings ??= {
		loggedIn: false,
		tenant: null,
		availableTenants: [],
		priviledges: [],
		currentPublication: null,
	};

	return settings;
}
