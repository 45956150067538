import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import Log from "../common/logger";
import { getGroupName, getSubGroupName } from "../common/groups";
import { deepClone, ensureIntOrThrow, nullOrWhitespaceTrim, throwError } from "../utils";
import * as routes from "../routes";
import * as entities from '../common/entities';
import * as rules from "../common/itemRules";

import { useSettings } from "../providers/SettingsContext";
import { useLayout } from "./Layout";
import { usePublicationsSource, useReferencesSource } from "../providers/DataSources";
import { useLanguage } from "../providers/LanguageContext";
import { useDialog } from "../providers/Dialogs";

import * as icons from "../media/icons";
import BackButton from "../components/BackButton";
import SubGroupSelection from "../components/SubGroupSelection";
import { InputPrice } from "../components/inputs";
import Translatable from "../components/Translatable";

function Reference() {
	const navigate = useNavigate();
	const { pubId, itemNumber } = useParams<{ pubId: string, itemNumber: string }>();

	const { newLog, hasAccess } = useSettings();
	const readOnly = (!hasAccess('reference_manage'));
	const { language } = useLanguage();
	const { setHeaderPlaceholder: setHeaderButtons } = useLayout();
	const { confirm } = useDialog();
	const pubDs = usePublicationsSource();
	const refDs = useReferencesSource();

	const [pageData, setPageData] = useState<null | Awaited<ReturnType<typeof loadPageData>>>(null);
	const origData = useRef<entities.Reference>(null!);
	const [currData, setCurrData] = useState<entities.Reference>(null!);
	const refs = useRef({
		readOnly,
		isModified: false,
		handleSave: () => { },
		handleDelete: (confirmed: boolean) => { },
		handleCancel: () => { },
	}).current;

	// Retreive publication & references
	useEffect(() => {
		(async () => {
			const log = newLog('loadPageData');
			const d = await loadPageData(log, pubId, itemNumber, pubDs, refDs);
			setPageData(d);
			if (d === 'error')
				return;
			origData.current = d.ref;
			setCurrData(d.ref);
		})();
	}, [newLog, pubId, itemNumber, pubDs, refDs]);

	// place action buttons in the layout's header
	useEffect(() => {
		setHeaderButtons(
			ReferenceButtons(refs)
		);
		// restore layout's header at umount
		return () => setHeaderButtons(null);
	}, [setHeaderButtons, currData, refs, refs.isModified]);

	if (pageData == null)
		// Page not ready to display
		return <icons.LoadingSpinnerPage />;
	if (pageData === 'error')
		// Page failed to load
		return <icons.LoadingError />;

	const publication = pageData.pub;
	const reference = currData;
	const state = new rules.ReferenceState(reference);
	refs.isModified = (JSON.stringify(reference) !== JSON.stringify(origData.current));

	function handleFormChange(cb: (r: entities.Reference) => void) {
		const cloned = deepClone(reference);
		cb(cloned);
		setCurrData(cloned);
	}

	refs.handleCancel = () => {
		setCurrData(origData.current);
	}

	refs.handleSave = async () => {
		const log = newLog('handleSave');
		const newRef = await saveReference(log, refDs, publication.publicationId, itemNumber!, currData);
		if (newRef == null)
			// Error
			return;
		// Reset & update URL (ie. in case of itemNumber change)
		origData.current = newRef;
		setCurrData(newRef);
		navigate(routes.reference(newRef.publicationId, newRef.itemNumber), { replace: true })
	}

	refs.handleDelete = async (confirmed: boolean = false): Promise<void> => {
		if (!confirmed) {
			if (await confirm({
				message: {
					fr: 'Êtes-vous sûr de vouloir supprimer cet enregistrement ?',
					nl: 'Bent u zeker dat u dit record wilt verwijderen?',
				},
			})) {
				refs.handleDelete(true);
			}
			return;
		}
		const log = newLog('handleDelete');
		const orig = currData;
		setPageData(null);  // Show spinner
		if (await deleteReference(log, refDs, parseInt(pubId!), itemNumber!))
			navigate(-1);  // Go back
		else  // Error
			setCurrData(orig);
	}

	return <Container
		className="mt-3"
	>
		<Form.Group as={Row}>
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Publication:',
					nl: 'Publicatie:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<Form.Control
					plaintext
					readOnly
					value={`${publication.date} (${publication.publicationId})`}
				/>
			</Col>
		</Form.Group>

		<Form.Group as={Row}>
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Numéro d\'article:',
					nl: 'Artikelnummer:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<Form.Control
					plaintext={readOnly}
					readOnly={readOnly}
					value={reference.itemNumber}
					onChange={(e) => handleFormChange(f => f.itemNumber = e.target.value)}
				/>
			</Col>

			<Form.Label column className="col-2 text-end text-nowrap">
				{((publication.prevId != null) && (reference.prevItemNumber != null)) ?
					<Link
						to={routes.reference(publication.prevId, reference.prevItemNumber)}
						style={{ color: 'inherit' }}
					>
						<icons.PageLinkOther />
						<Translatable>{{
							fr: 'précédent:',
							nl: 'vorige:',
						}}</Translatable>
					</Link>
					: readOnly ?
						<></>
						:
						<Translatable>{{
							fr: 'précédent:',
							nl: 'vorige:',
						}}</Translatable>
				}
			</Form.Label>
			<Col>
				<Form.Control
					plaintext={readOnly}
					readOnly={readOnly}
					value={reference.prevItemNumber ?? ''}
					onChange={(e) => handleFormChange(r => r.prevItemNumber = nullOrWhitespaceTrim(e.target.value))}
				/>
			</Col>
		</Form.Group>

		{entities.allLanguages.map(lang =>
			<Form.Group as={Row} key={lang} className="mt-3">
				<Form.Label column className="col-3 text-nowrap">
					<Translatable>{{
						fr: `Nom (${lang}):`,
						nl: `Naam (${lang}):`,
					}}</Translatable>
				</Form.Label>
				<Col>
					<Form.Control
						plaintext={readOnly}
						readOnly={readOnly}
						value={reference.name[lang] ?? ''}
						onChange={(e) => handleFormChange(r => r.name[lang] = e.target.value)}
					/>
				</Col>
			</Form.Group>
		)}

		<Form.Group as={Row} className="mt-3">
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Prix:',
					nl: 'Prijs:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<InputPrice
					className={`${readOnly ? 'form-control-plaintext' : 'form-control'}`}
					readOnly={readOnly}
					initialValue={reference.price}
					onChange={(v) => handleFormChange(r => r.price = v ?? 0)}
				/>
			</Col>
		</Form.Group>

		<Form.Group as={Row} className="mt-3">
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Groupe:',
					nl: 'Groep:',
				}}</Translatable>
			</Form.Label>
			<Col as={InputGroup}>
				{readOnly && (state.item.subGroup != null) &&
					<Form.Control
						plaintext
						readOnly
						value={`${state.item.subGroup}: ${getGroupName(state.item.subGroup, language)} | ${getSubGroupName(state.item.subGroup, language)}`}
					/>
				}
				{readOnly ||
					<SubGroupSelection
						initial={reference.subGroup ?? null}
						onChange={(g) => handleFormChange(r => r.subGroup = g)}
					/>
				}
			</Col>
		</Form.Group>

		{readOnly ||  // nb: do not show errors to regular users
			state.errors.map((err) =>
				<div key={err} className="alert alert-danger" role="alert">
					{rules.ruleErrorTexts[err][language]}
				</div>)}
	</Container>
}

function ReferenceButtons({ readOnly, isModified, handleSave, handleDelete, handleCancel }: {
	readOnly: boolean,
	isModified: boolean,
	handleSave: () => void,
	handleDelete: (confirmed: boolean) => void,
	handleCancel: () => void,
}) {
	const showSave = (!readOnly) && isModified;
	const showDelete = (!readOnly);
	const showCancel = (!readOnly) && isModified;

	return <span>
		<BackButton />

		{showSave &&
			<button type="submit" className="btn btn-primary ms-1" onClick={handleSave}>
				<Translatable>{{
					fr: `Enregistrer`,
					nl: `Opslaan`,
				}}</Translatable>
			</button>
		}

		{showDelete &&
			<button type="submit" className="btn btn-primary ms-1" onClick={() => handleDelete(false)}>
				<Translatable>{{
					fr: `Supprimer`,
					nl: `Verwijderen`,
				}}</Translatable>
			</button>
		}

		{showCancel &&
			<button type="submit" className="btn btn-secondary ms-1" onClick={handleCancel}>
				<Translatable>{{
					fr: `Annuler`,
					nl: `Annuleren`,
				}}</Translatable>
			</button>
		}
	</span>
}

async function loadPageData(
	log: Log,
	strPublicationId: string | undefined,
	itemNumber: string | undefined,
	pubDs: ReturnType<typeof usePublicationsSource>,
	refDs: ReturnType<typeof useReferencesSource>,
): Promise<{ pub: entities.Publication, ref: entities.Reference } | 'error'> {
	try {
		const publicationId = ensureIntOrThrow({ n: strPublicationId, log, errorMsg: `Invalid publication id specified` });
		itemNumber = itemNumber ?? throwError(`Item number not specified`, log);
		const pubTask = pubDs.get({ log, publicationId });
		const refTask = refDs.get({ log, publicationId, itemNumber });
		return {
			pub: (await pubTask)[0] ?? throwError(`Publication ${publicationId} not found`, log),
			ref: (await refTask)[0] ?? throwError(`Reference ${publicationId}/${itemNumber} not found`, log),
		};
	}
	catch (error) {
		log.error('Error while fetching page data');
		log.exception(error);
		return 'error';
	}
}

async function deleteReference(
	log: Log,
	refDs: ReturnType<typeof useReferencesSource>,
	publicationId: number,
	itemNumber: string,
): Promise<boolean> {
	try {
		await refDs.delete({ log, items: [{ publicationId, itemNumber }] });
		return true;
	}
	catch (error) {
		log.error('Error while deleting the reference');
		log.exception(error);
		return false;
	}
}

async function saveReference(
	log: Log,
	refDs: ReturnType<typeof useReferencesSource>,
	publicationId: number,
	itemNumber: string,
	reference: entities.Reference,
): Promise<entities.Reference | null> {
	try {
		const rc = await refDs.update({ log, items: [{ publicationId, itemNumber, reference }] });
		if (rc.length !== 1)
			throwError(`Server returned a wrong number of items: ${rc.length}`);
		return rc[0];
	}
	catch (error) {
		log.error('Error while saving the reference');
		log.exception(error);
		return null;
	}
}

export default Reference;
