import { Adjustment, Language, Publication, Reference, Settings } from "./entities";
import { queryUrl } from "./utils";

let prefix: string;
export const setFrontend = () => prefix = '/api';
export const setApi = () => prefix = '';

export namespace general {
	export const settings = () => `${prefix}/settings`;
	export type SettingsResult = Settings;

	export const getAllTenants = () => `${prefix}/tenants/all`;
	export type GetAllTenantsResult = { tenants: string[] };

	export const useTenant = () => `${prefix}/tenant/use`;
	export type UseTenantRequest = { tenant: string };
	export type UseTenantResult = {};

	export const login = (backUrl?: string) => `${prefix}/login`
		+ ((backUrl == null) ? `` : `?back-url=${encodeURIComponent(backUrl)}`);
	export const loginCallback = () => `${prefix}/oidc-callback`;

	export const logout = () => `${prefix}/logout`;
	export type LogoutResult = { logoutFlowUrl: string };
}

export namespace publication {
	export const get = () => `${prefix}/publication`;
	export type GetRequest = { publicationId: number, recursive?: boolean };
	export type GetResult = { result: Publication[] };

	export const setCurrent = () => `${prefix}/publications/set-current`;
	export type SetCurrentRequest = { publicationId: number };

	export const update = () => `${prefix}/publication`;
	export type UpdateRequest = Publication;
	export type UpdateResult = { result: Publication };

	export const upload = (p?: UploadRequest) => queryUrl(`${prefix}/publication/upload`, p ?? {});
	export type UploadRequest = { lang: Language, dstPublicationId?: number, prevId?: number };
	export type UploadResult = { publication: Publication, fileName: string, length: number };
}

export namespace reference {
	export const get = () => `${prefix}/reference`;
	export type GetRequest = { publicationId: number, itemNumber?: string, prevItemNumber?: string };
	export type GetResult = { result: Reference[] };

	export const add = () => `${prefix}/reference`;

	export const update = () => `${prefix}/reference`;
	export type UpdateRequest = { items: { publicationId?: number; itemNumber?: string, reference: Partial<Reference> }[] };
	export type UpdateResult = { result: Reference[] };

	export const remove = () => `${prefix}/reference`;
	export type RemoveRequest = { items: { publicationId: number; itemNumber: string }[] };
	export type RemoveResult = {};
}

export namespace adjustment {
	export const get = () => `${prefix}/adjustments`;
	export type GetRequest = { itemNumber?: string };
	export type GetResult = { result: Adjustment[] };

	export const saveSingle = () => `${prefix}/adjustment`;
	export type SaveRequest = { itemNumber: string, adjustment: Adjustment };
	export type SaveResult = { result: Adjustment };

	export const deleteSingle = () => `${prefix}/adjustment`;
	export type DeleteRequest = { itemNumber: string };
	export type DeleteResult = { result: Adjustment | null };
}
