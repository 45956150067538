import Log from "./logger";
import { throwError } from "./utils";
import { Publication, Reference } from "./entities";
import PublicationsStore from "./publicationsStore";

export abstract class ReferencesStore {
	protected abstract getPublicationsStore(): PublicationsStore;
	public abstract get(p: {
		log: Log,
		publicationId: number,
		itemNumber?: string,
		prevItemNumber?: string,
	}): Promise<Reference[]>;
	public abstract add(p: {
		log: Log,
		references: Reference[],
	}): Promise<Reference[]>;
	public abstract update(p: {
		log: Log,
		items: {
			publicationId?: number,
			itemNumber?: string,
			reference: Partial<Reference>,
		}[],
	}): Promise<Reference[]>;
	public abstract delete(p: {
		log: Log,
		items: {
			publicationId: number,
			itemNumber: string,
		}[],
	}): Promise<void>;

	public async updateReferences({ log, publication, references, createMissingFromPrevious = false }: {
		log: Log,
		publication: Publication,
		references: Reference[],
		createMissingFromPrevious?: boolean
	}) {
		const items: {
			[k: string]: {
				updt?: Reference,
				curr?: Reference,
				prev?: Reference,
			}
		} = {};

		function setItem(itemNumber: string, cb: (v: typeof items[0]) => void) {
			const item = items[itemNumber] ?? {};
			cb(item);
			items[itemNumber] = item;
		}

		log.log(`Retreive references of destination publication ${publication.publicationId}`);
		for (const curr of await this.get({ log, publicationId: publication.publicationId }))
			setItem(curr.itemNumber, (v) => v.curr = curr);

		if (publication.prevId != null) {
			log.log(`Retreive references of previous publication ${publication.prevId}`);
			for (const prev of await this.get({ log, publicationId: publication.prevId }))
				setItem(prev.itemNumber, (v) => v.prev = prev);
		}

		log.log(`Prepare ${references.length} references`);
		for (const ref of references) {
			const itemNumber = ref.itemNumber ?? throwError(`Entry with missing 'itemNumber'`, log);
			setItem(itemNumber, (item) => {
				const src = item.curr ?? item.prev;

				const updt = (src != null)
					? JSON.parse(JSON.stringify(src)) as Reference   // Clone from source
					: {} as Reference // New item from scratch

				// Update available fields
				Object.entries(ref).forEach(([key, value]) => {
					switch (key) {
						case 'name':
							updt.name = { ...updt.name, ...ref.name };
							break;
						default:
							(updt as any)[key] = value;
					}
				});
				// Ensure required fields
				updt.publicationId = publication.publicationId;
				updt.prevItemNumber ??= item.prev?.itemNumber;
				updt.name = updt.name ?? [];
				updt.price = updt.price ?? 0;

				item.updt = updt;
			});
		}

		if (createMissingFromPrevious) {
			const toAdd = Object.values(items)
				.filter(v => (v.updt == null) && (v.curr == null) && (v.prev != null));  // Items that are in previous publication & not in current & not planned for update yet
			toAdd.forEach(v => {
				// Clone from prev
				v.updt = JSON.parse(JSON.stringify(v.prev)) as Reference;
				v.updt.publicationId = publication.publicationId;
				v.updt.prevItemNumber = v.updt.itemNumber;
			});
		}

		const toInsert = Object.values(items)
			.filter(v => (v.updt != null) && (v.curr == null))  // Items to be updated but don't have any current yet
			.map(v => v.updt!);

		const toUpdate = Object.values(items)
			.filter(v => (v.updt != null) && (v.curr != null))  // Items to be updated and already have a current
			.filter(v => JSON.stringify(v.updt) !== JSON.stringify(v.curr))  // with actual changes
			.map(v => ({ reference: v.updt! }));

		log.log(`Add ${toInsert.length} new references`);
		await this.add({ log, references: toInsert });

		log.log(`Update ${toUpdate.length} existing references`);
		await this.update({ log, items: toUpdate });
	}
}

export default ReferencesStore;
