import { useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { generateId } from "../common/utils";
import * as fltr from "../common/itemsFilters";

import Translatable from "./Translatable";

/** Items filter controls */
function ItemsFilter({ filter }: {
	filter: fltr.FilterBase,
}) {
	const { current: s } = useRef({
		idOrderDesc: generateId(),
		idErrorsFirst: generateId(),
		idAdjFirst: generateId(),
	});

	const adjFilter = (filter instanceof fltr.AdjustmentsFilter) ? filter as fltr.AdjustmentsFilter : null;
	const isAdjFilter = (adjFilter != null);

	return <>
		{/* order by field */}
		<Form.Group as={Row}>
			<Form.Label column className="col-2">
				<Translatable>{{
					fr: 'Trier sur:',
					nl: 'Sorteren op:',
				}}</Translatable>
			</Form.Label>

			<Col className="col-5">
				<Form.Select
					value={filter.orderBy}
					onChange={(evt) => filter.apply(f => f.orderBy = evt.target.value as fltr.OrderBy)}
				>
					{fltr.allOrderBys.map(option => (
						<option key={option} value={option}>
							<Translatable>{fltr.orderByNames[option as fltr.OrderBy]}</Translatable>
						</option>
					))}
				</Form.Select>
			</Col>

			<Form.Label column>
				<Form.Check type="checkbox"
					id={s.idOrderDesc}
					className="col-4"
					label={<Translatable>{{
						fr: 'décroissant',
						nl: 'afnemend',
					}}</Translatable>}
					checked={filter.orderDesc}
					onChange={(e) => filter.apply((f) => f.orderDesc = e.target.checked)}
				/>
			</Form.Label>
		</Form.Group>

		<Form.Group className="mb-3">
			{/* order by errors first */}
			<Form.Check type="checkbox"
				id={s.idErrorsFirst}
				label={<Translatable>{{
					fr: 'Alertes en premier',
					nl: 'Alerts eerst',
				}}</Translatable>}
				checked={filter.errorsFirst}
				onChange={(e) => filter.apply((f) => f.errorsFirst = e.target.checked)}
			/>

			{/* order by adjusted price first */}
			{isAdjFilter &&
				<Form.Check type="checkbox"
					id={s.idAdjFirst}
					label={<Translatable>{{
						fr: 'Prix ajustés en premier',
						nl: 'Eerst aangepaste prijzen',
					}}</Translatable>}
					checked={adjFilter.adjFirst}
					onChange={(e) => adjFilter.apply((f) => f.adjFirst = e.target.checked)}
				/>
			}
		</Form.Group>
	</>
}

export default ItemsFilter;
