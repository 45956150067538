import Log from "../common/logger";
import { apiRequest, queryUrl } from "../common/utils";
import * as routes from "../routes";
import { Adjustment, Publication, Reference } from "../common/entities";
import PublicationsStore from "../common/publicationsStore";
import ReferencesStore from "../common/referencesStore";
import AdjustmentsStore from "../common/adjustmentsStore";

class PublicationsStoreApi extends PublicationsStore {
	protected override getReferencesStore() {
		return refStore;
	}

	public override async getCurrentId(): Promise<number | null> {
		throw new Error("Not implemented: PublicationsStoreApi.getCurrentId().");
	}

	public override async setCurrentId(publicationId: number): Promise<void> {
		const body: routes.api.publication.SetCurrentRequest = { publicationId };
		await apiRequest<{}>({
			method: 'POST',
			url: routes.api.publication.setCurrent(),
			exceptionMessage: 'Unable to set current publication',
			body,
		});
	}

	public override async get({ log, publicationId, recursive }: {
		log: Log,
		publicationId: number,
		recursive?: boolean,
	}): Promise<Publication[]> {
		const query: routes.api.publication.GetRequest = { publicationId, recursive };
		log.log('pubstore: get', query);
		const result = await apiRequest<routes.api.publication.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.publication.get(), query),
			exceptionMessage: 'Error while fetching publications',
		});
		return result.result;
	}

	public override async update({ log, publication }: {
		log: Log,
		publication: Publication,
	}): Promise<Publication> {
		const body: routes.api.publication.UpdateRequest = publication;
		log.log('pubstore: update', body);
		const result = await apiRequest<routes.api.publication.UpdateResult>({
			method: 'POST',
			url: routes.api.publication.update(),
			body,
			exceptionMessage: 'Error while saving publication',
		});
		return result.result;
	}
}

class ReferencesStoreApi extends ReferencesStore {
	protected override getPublicationsStore() {
		return pubStore;
	}

	public override async get({ log, publicationId, itemNumber, prevItemNumber }: {
		log: Log,
		publicationId: number,
		itemNumber?: string,
		prevItemNumber?: string,
	}): Promise<Reference[]> {
		const query: routes.api.reference.GetRequest = { publicationId, itemNumber, prevItemNumber };
		log.log('refstore: get', query);
		const result = await apiRequest<routes.api.reference.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.reference.get(), query),
			exceptionMessage: 'Error while fetching references',
		});
		return result.result;
	}

	public override add(p: { log: Log; references: Reference[]; }): Promise<Reference[]> {
		throw new Error("Not implemented: ReferencesStoreApi.add().");
	}

	public override async update({ log, items }: {
		log: Log,
		items: {
			publicationId?: number,
			itemNumber?: string,
			reference: Partial<Reference>,
		}[],
	}): Promise<Reference[]> {
		const body: routes.api.reference.UpdateRequest = { items };
		log.log('refstore: update', body);
		const result = await apiRequest<routes.api.reference.UpdateResult>({
			method: 'POST',
			url: routes.api.reference.update(),
			body,
			exceptionMessage: 'Error while saving references',
		});
		return result.result;
	}

	public override async delete({ log, items }: {
		log: Log,
		items: {
			publicationId: number,
			itemNumber: string,
		}[],
	}): Promise<void> {
		const body: routes.api.reference.RemoveRequest = { items };
		log.log('refstore: delete', body);
		await apiRequest<routes.api.reference.RemoveResult>({
			method: 'DELETE',
			url: routes.api.reference.remove(),
			body,
			exceptionMessage: 'Error while deleting references',
		});
	}
}

class AdjustmentsStoreApi extends AdjustmentsStore {
	public override async get({ log, itemNumber }: {
		log: Log,
		itemNumber?: string,
	}): Promise<Adjustment[]> {
		const body: routes.api.adjustment.GetRequest = { itemNumber };
		log.log('adjstore: get', body);
		const result = await apiRequest<routes.api.adjustment.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.adjustment.get(), body),
			exceptionMessage: 'Error while fetching adjustments',
		});
		return result.result;
	}

	public override async save({ log, itemNumber, adjustment }: {
		log: Log,
		itemNumber: string,
		adjustment: Adjustment,
	}): Promise<Adjustment> {
		const body: routes.api.adjustment.SaveRequest = { itemNumber, adjustment };
		log.log('adjstore: save', body);
		const result = await apiRequest<routes.api.adjustment.SaveResult>({
			method: 'POST',
			url: routes.api.adjustment.saveSingle(),
			body,
			exceptionMessage: 'Error while saving adjustment',
		});
		return result.result;
	}

	public override async delete({ log, itemNumber }: {
		log: Log,
		itemNumber: string,
	}): Promise<Adjustment | null> {
		const body: routes.api.adjustment.DeleteRequest = { itemNumber };
		log.log('adjstore: delete', body);
		const result = await apiRequest<routes.api.adjustment.DeleteResult>({
			method: 'DELETE',
			url: routes.api.adjustment.deleteSingle(),
			body,
			exceptionMessage: 'Error while deleting adjustment',
		});
		return result.result;
	}
}

const pubStore = new PublicationsStoreApi();
const refStore = new ReferencesStoreApi();
const adjStore = new AdjustmentsStoreApi();

export function usePublicationsSource() {
	return pubStore;
}

export function useReferencesSource() {
	return refStore;
}

export function useAdjustmentsStore() {
	return adjStore;
}
