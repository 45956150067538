import Log from "./logger";
import { Publication } from "./entities";
import ReferencesStore from "./referencesStore";

export abstract class PublicationsStore {
	protected abstract getReferencesStore(): ReferencesStore;
	public abstract getCurrentId(): Promise<number | null>;
	public abstract setCurrentId(currentId: number): Promise<void>;
	public abstract get(p: {
		log: Log,
		publicationId: number,
		recursive?: boolean,
	}): Promise<Publication[]>;
	public abstract update(p: {
		log: Log,
		publication: Publication,
	}): Promise<Publication>;
}

export default PublicationsStore;
