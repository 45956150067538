import { useEffect, useRef, useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Log from '../common/logger';
import { dateString, dateValue, deepClone, ensureIntOrThrow, generateId, throwError } from '../utils';
import * as routes from '../routes';
import * as entities from '../common/entities';
import { ReferenceState } from '../common/itemRules';
import * as fltr from '../common/itemsFilters';

import { useSettings } from '../providers/SettingsContext';
import { useLanguage } from '../providers/LanguageContext';
import { useDialog } from '../providers/Dialogs';
import { usePublicationsSource, useReferencesSource } from '../providers/DataSources';

import { useLayout } from './Layout';
import * as icons from '../media/icons';
import { DatePicker } from '../components/inputs';
import Translatable from '../components/Translatable';
import ItemsFilter from '../components/ItemsFilter';
import ReferencesTable from '../components/ReferencesTable';

type FormData = {
	publication: entities.Publication,
	initialPublication: entities.Publication,
	isCurrent: boolean,
	isEdited: boolean,
	isBusy: boolean,
}

/** main content */
function Publication() {
	const { id: publicationId } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const location = useLocation();

	const { settings, newLog, refreshSettings, hasAccess } = useSettings();
	const canModifyPublication = hasAccess('publication_manage');
	const { language, translate } = useLanguage();
	const { confirm } = useDialog();
	const { setHeaderPlaceholder: setHeaderButtons } = useLayout();
	const pubSrc = usePublicationsSource();
	const refSrc = useReferencesSource();

	const [pageData, setPageData] = useState<null | Awaited<ReturnType<typeof loadPageData>>>(null);
	const [filter, setFilter] = useState<null | fltr.ReferencesFilter>(null);
	const [formData, setFormData] = useState<null | FormData>(null);
	const callbacks = useRef({ // nb: required because they are referenced in 'useEffect()' before they are declared
		handleFormChange: (cb: (data: FormData) => void) => { },
		handlePublicationSave: () => { },
		handleSetAscurrent: (confirmed: boolean) => { },
	}).current;

	// retrieve page data
	useEffect(() => {
		setPageData(null);  // [re]set state

		(async () => {
			const log = newLog('loadPageData');
			const data = await loadPageData(log, publicationId, pubSrc, refSrc);
			setPageData(data);
		})();
	}, [newLog, publicationId, pubSrc, refSrc]);

	// apply filter & prepare formData
	useEffect(() => {
		if ((pageData == null) || (pageData === 'error')) {
			// [re]set state
			setFilter(null);
			setFormData(null);
			return;
		}

		let fl = filter;
		if (fl == null) {
			fl = new fltr.ReferencesFilter(
				language,
				pageData.states,
				() => navigate(fl!.toUrlParms(), { replace: true })  // whenever the filter changes, update URL with the new parameters
			);
			setFilter(fl);
		}
		fl.fromUrlParms(location.search);

		setFormData(
			computeFormData(settings, {
				publication: deepClone(pageData.publication),
				initialPublication: pageData.publication,
				isBusy: false,
			})
		);
	}, [settings, language, pageData, filter, navigate, location.search]);

	// place action buttons in the layout's header
	useEffect(() => {
		if ((!canModifyPublication)  // no need to show action buttons when read-only
			|| (formData == null)) {
			setHeaderButtons(null);
			return;
		}

		setHeaderButtons(
			PublicationButtons({  // nb: returns 'null' when nothing to display => will restore the layout's default header
				formData,
				handleFormChange: callbacks.handleFormChange,
				onSave: callbacks.handlePublicationSave,
				onSetAsCurrent: () => callbacks.handleSetAscurrent(/*confirmed*/false),
			})
		);

		// restore layout's header at umount
		return () => setHeaderButtons(null);
	}, [canModifyPublication, formData, setHeaderButtons, callbacks]);

	if (pageData === 'error')
		return <icons.LoadingError />;
	if ((pageData == null) || (filter == null) || (formData == null))
		return <icons.LoadingSpinnerPage />;

	function computeFormData<T extends { initialPublication: entities.Publication, publication: entities.Publication }>(settings: entities.Settings, data: T): T & { isCurrent: boolean, isEdited: boolean } {
		return {
			...data,
			isCurrent: (settings.currentPublication?.id === data.publication.publicationId),
			isEdited: (JSON.stringify(data.initialPublication) !== JSON.stringify(data.publication)),
		};
	}

	callbacks.handleFormChange = (cb) => {
		cb(formData);  // apply change
		setFormData({  // force trigger refresh
			...computeFormData(settings, formData),  // recompute fields
		});
	}

	callbacks.handlePublicationSave = async () => {
		const log = newLog('handlePublicationSave');
		try {
			callbacks.handleFormChange((d) => d.isBusy = true);
			const updated = await pubSrc.update({ log, publication: formData.publication });
			setPageData({ ...pageData, publication: updated });
		} catch (ex) {
			log.error(`Error while updating the publication`);
			log.exception(ex);
		} finally {
			callbacks.handleFormChange((d) => d.isBusy = false);
		}
	}

	callbacks.handleSetAscurrent = async (confirmed) => {
		if (!confirmed) {
			if (await validateSetAscurrent())
				callbacks.handleSetAscurrent(true);
			return;
		}

		callbacks.handleFormChange((d) => d.isBusy = true);
		try {
			await pubSrc.setCurrentId(formData.publication.publicationId);
			await refreshSettings();
		}
		finally {
			callbacks.handleFormChange((d) => d.isBusy = false);
		}
	}

	const validateSetAscurrent = async () => {
		callbacks.handleFormChange((d) => d.isBusy = true);
		const log = newLog('validate')
		try {
			const newPub = formData.publication;
			const prevPubId = newPub.prevId;
			const currPubId = settings.currentPublication?.id;
			const warnings = [] as entities.TranslatedString[];

			if (prevPubId !== currPubId)
				warnings.push({
					fr: 'La publication définie comme précédente n\'est pas l\'actuelle',
					nl: 'De publicatie die als voorheen is ingesteld, is niet actueel',
				});

			if (prevPubId != null) {
				const prevPub = (await pubSrc.get({
					log,
					publicationId: prevPubId,
					recursive: false,
				}))[0] ?? throwError('Error retrieving previous publication', log);

				if (newPub.date < prevPub.date)
					warnings.push({
						fr: 'La date PRÉCÈDE celle de la publication précédente',
						nl: 'De datum GAAT VOOR die van de vorige publicatie',
					});
			}

			if (filter.allItems.find(v => v.hasErrors()) != null)
				warnings.push({
					fr: 'Il reste des erreurs',
					nl: 'Er zijn nog steeds fouten',
				});

			return await confirm({
				title: {
					fr: 'Définir comme actuel?',
					nl: 'Instellen als actueel',
				},
				content: <ul>
					{warnings.map((v) => <li key={generateId()}>{translate(v)}</li>)}
				</ul>,
			});
		} catch (ex) {
			log.error('Error during publication validation');
			log.exception(ex);
		} finally {
			callbacks.handleFormChange((d) => d.isBusy = false);
		}
	}

	return <Container
		fluid
		className="mt-3"
	>
		<PublicationHeader
			readOnly={!canModifyPublication}
			formData={formData}
			handleFormChange={callbacks.handleFormChange}
		/>

		<hr />

		<ItemsFilter
			filter={filter}
		/>

		<ReferencesTable
			filter={filter}
		/>
	</Container>
}// main content

/** header (ie. publication's informations) */
function PublicationHeader({ readOnly, formData, handleFormChange }: {
	readOnly: boolean,
	formData: FormData,
	handleFormChange: (cb: (d: FormData) => void) => void,
}) {
	const { publication } = formData;

	return <>
		{/* id */}
		<Form.Group as={Row}>
			<Form.Label column className="col-2">
				<Translatable>{{
					fr: 'ID:',
					nl: 'ID:',
				}}</Translatable>
			</Form.Label>
			<Col className="col-4">
				<Form.Control
					plaintext
					readOnly
					value={publication.publicationId}
				/>
			</Col>

			{/* link to previous publication */}
			{(publication.prevId != null) && <>
				<Form.Label column className="col-2 text-end">
					<Translatable>{{
						fr: 'Précédent:',
						nl: 'Vorige:',
					}}</Translatable>
				</Form.Label>
				<Col className="col-4">
					<Link
						className="form-control-plaintext"
						to={routes.publication(publication.prevId)}
					>
						<icons.PageLinkOther />
						{` ${publication.prevId}`}
					</Link>
				</Col>
			</>}
		</Form.Group>

		{/* date */}
		<Form.Group as={Row}>
			<Form.Label column>
				<Translatable>{{
					fr: `Date:`,
					nl: `Datum:`,
				}}</Translatable>
			</Form.Label>
			<DatePicker
				outerClassName="col-10"
				innerClassName={readOnly ? "form-control-plaintext" : "form-control"}
				value={dateValue({ str: publication.date })!}
				allowNull={false}
				onChange={readOnly ? undefined : (dt) => handleFormChange(v => v.publication.date = dateString({ d: dt, withTime: false }))}
			/>
		</Form.Group>
	</>
}// header (ie. publication's informations)

/** publication's action buttons (ie. placed in the layout's header) */
function PublicationButtons({ formData, handleFormChange, onSave, onSetAsCurrent }: {
	formData: FormData,
	handleFormChange: (cb: (d: FormData) => void) => void,
	onSave: () => void,
	onSetAsCurrent: () => void,
}) {
	const { isCurrent, isEdited, isBusy } = formData;
	const showSave = isEdited;
	const showSetAsCurrent = (!isCurrent);

	function handleCancel() {
		handleFormChange(
			(d) => d.publication = deepClone(d.initialPublication)
		);
	}

	if (isBusy)
		return <icons.LoadingSpinnerButton />

	if (showSave)
		return <span>
			<button type="submit"
				className="btn btn-primary"
				onClick={onSave}>
				<Translatable>{{
					fr: `Enregistrer`,
					nl: `Opslaan`,
				}}</Translatable>
			</button>

			<button type="submit"
				className="btn btn-secondary ms-1"
				onClick={handleCancel}>
				<Translatable>{{
					fr: `Annuler`,
					nl: `Annuleren`,
				}}</Translatable>
			</button>
		</span>

	if (showSetAsCurrent)
		return (
			<button type="submit"
				className="btn btn-primary"
				onClick={onSetAsCurrent}>
				<Translatable>{{
					fr: 'Publier comme actuel',
					nl: 'Publiceer als actueel',
				}}</Translatable>
			</button>
		)

	// no button to display ; ie. restore layout's default header
	return null;
}  // publication's action buttons (ie. placed in the layout's header)

async function loadPageData(
	log: Log,
	strPublicationId: string | undefined,
	pubSrc: ReturnType<typeof usePublicationsSource>,
	refSrc: ReturnType<typeof useReferencesSource>,
) {
	try {
		const publicationId = ensureIntOrThrow({ n: strPublicationId, log, errorMsg: `Invalid publication id specified` });
		const pubTask = pubSrc.get({ log, publicationId });
		const refsTask = refSrc.get({ log, publicationId });

		const references = await refsTask;
		const states = references.map((reference) => new ReferenceState(reference));

		return {
			publication: (await pubTask)[0] ?? throwError(`Publication ${publicationId} not found`, log),
			references,
			states,
		};
	}
	catch (error) {
		log.error(`Error while fetching page data`);
		log.exception(error);
		return 'error';
	}
}

export default Publication;
