import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import * as routes from "../routes";
import { Language } from "../common/entities";
import { ApiError, generateId } from "../common/utils";

import { useLanguage } from "../providers/LanguageContext";
import { useErrorHandler } from "../providers/ErrorManagerContext";

import * as icons from "../media/icons";
import Translatable from "../components/Translatable";
import LanguageSelection from "../components/LanguageSelection";

function PublicationUpload(): JSX.Element {
	const navigate = useNavigate();
	const { language: appLang, translate } = useLanguage();
	const errorHandler = useErrorHandler();
	const [lang, setLang] = useState<Language>(appLang);
	const [dstPublicationId, setDstPublicationId] = useState<number | undefined>(undefined);
	const [prevId, setPrevId] = useState<number | undefined>(undefined);
	const [uploading, setUploading] = useState<boolean>(false);
	const [s] = useState({
		dstPublicationId: generateId(),
		idPrevId: generateId(),
		idFile: generateId(),
	});

	async function handleFileChange(evt: React.ChangeEvent<HTMLInputElement>): Promise<void> {
		try {
			setUploading(true);

			const file = evt.target.files?.[0];
			if (!file)
				return;

			const formData = new FormData();
			formData.append('file', file);
			const response = await fetch(routes.api.publication.upload({ lang, dstPublicationId, prevId }), {
				method: 'POST',
				body: formData,
			});
			if (!response.ok)
				throw new ApiError('File upload failed', response);

			const result = await response.json() as routes.api.publication.UploadResult;
			navigate(routes.publication(result.publication.publicationId));
		}
		catch (error) {
			await errorHandler.addError({ message: 'Error uploading file', error });
		}
		finally {
			setUploading(false);
		}
	}

	function ensureNumber(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.value === '')
			return undefined;
		const n = Number(e.target.value);
		if (isNaN(n))
			return undefined;
		return n
	}

	if (uploading)
		return <icons.LoadingSpinnerPage />

	return <Container
		className="mt-3"
	>
		<div className="input-group mb-3">
			<span className="input-group-text">
				<Translatable>{{
					fr: 'Langue du ficher',
					nl: 'Taal van het bestand',
				}}</Translatable>
			</span>
			<span className="form-control">
				<LanguageSelection
					value={lang}
					onChanged={setLang}
				/>
			</span>
		</div>

		<div className="input-group mb-3">
			<label htmlFor={s.dstPublicationId} className="input-group-text">
				<Translatable>{{
					fr: 'Destination',
					nl: 'Bestemming',
				}}</Translatable>
			</label>
			<input type="text" id={s.dstPublicationId} value={dstPublicationId ?? ''}
				onChange={(e) => setDstPublicationId(ensureNumber(e))}
				className="form-control"
				placeholder={translate({
					fr: 'Publication de destination: Laisser vide pour créer une nouvelle',
					nl: 'Bestemmingspublicatie: Leeg laten om een nieuwe te creëren',
				})} />
		</div>

		<div className="input-group mb-3">
			<label htmlFor={s.idPrevId} className="input-group-text"><Translatable>{{
				fr: 'Référence',
				nl: 'Referentie',
			}}</Translatable></label>
			<input type="text" id={s.idPrevId} value={prevId ?? ''}
				onChange={(e) => setPrevId(ensureNumber(e))}
				className="form-control"
				placeholder={translate({
					fr: 'Publication de référence (ie. précédente): Laisser vide pour utiliser celle en cours',
					nl: 'Referentiepublicatie (d.w.z. voorgaande): Laat leeg om de huidige te gebruiken',
				})} />
		</div>

		<div className="input-group mb-3">
			<input type="file" id={s.idFile} name="file" className="form-control" onChange={handleFileChange} />
		</div>
	</Container>
}

export default PublicationUpload;
