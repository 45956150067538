import { Language, TranslatedString } from "./entities";
import { arrayToHashset, nullOrWhitespaceTrim, strEnum } from "./utils"

export function getGroup(subGroup: string | null | undefined): Group | null {
	subGroup = nullOrWhitespaceTrim(subGroup);
	if (subGroup == null)
		return null;
	const rc = subGroup[0] as Group;
	if (!allGroups.includes(rc))
		return null;
	return rc;
}

export function getGroupName(group: string | null | undefined, lang: Language): string | null {
	const grp = getGroup(group);
	if (grp == null)
		return null;
	const tr = groupNames[grp];
	if (tr == null)
		return null;
	return tr[lang];
}

export function getSubGroupName(subGroup: string | null | undefined, lang: Language): string | null {
	if (!allSubGroups.includes(subGroup as SubGroup))
		return null;
	const tr = subGroupNames[subGroup as SubGroup];
	if (tr == null)
		return null;
	return tr[lang];
}

export const { e: Groups, a: allGroups } = strEnum([
	'1',
	'3',
	'5',
	'6',
	'7',
	'8',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
]);
export const allGroupsSet = arrayToHashset(allGroups);
export type Group = keyof typeof Groups;

export const { e: SubGroups, a: allSubGroups } = strEnum([
	'1*',
	'1+',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'1A',
	'1B',
	'1C',
	'1D',
	'1E',
	'1F',
	'1G',
	'1H',
	'1I',
	'1J',
	'1K',
	'1L',
	'1M',
	'1N',
	'1O',
	'1P',
	'1Q',
	'1R',
	'1S',
	'1T',
	'1U',
	'1V',
	'1W',
	'1X',
	'1Y',
	'3A',
	'3B',
	'3C',
	'3D',
	'3G',
	'3H',
	'5A',
	'5B',
	'5C',
	'5D',
	'5E',
	'5G',
	'5H',
	'5I',
	'5J',
	'5K',
	'5L',
	'5N',
	'5P',
	'6H',
	'7A',
	'7B',
	'7C',
	'7D',
	'7E',
	'7F',
	'7H',
	'7I',
	'7J',
	'7K',
	'7L',
	'7M',
	'7N',
	'7O',
	'7P',
	'7Q',
	'7S',
	'8A',
	'8B',
	'8C',
	'8D',
	'8E',
	'8F',
	'8G',
	'8I',
	'BA',
	'BB',
	'BC',
	'BD',
	'BE',
	'BF',
	'BG',
	'BH',
	'CA',
	'CB',
	'CC',
	'CD',
	'CE',
	'CF',
	'CG',
	'CH',
	'CI',
	'CJ',
	'CK',
	'CM',
	'CN',
	'CO',
	'CP',
	'CQ',
	'CR',
	'CS',
	'CT',
	'CX',
	'CY',
	'DA',
	'DB',
	'DC',
	'DD',
	'DE',
	'DF',
	'DG',
	'DH',
	'DI',
	'DJ',
	'DK',
	'DL',
	'DM',
	'DN',
	'DO',
	'DP',
	'DQ',
	'DR',
	'DS',
	'DT',
	'DU',
	'DV',
	'DW',
	'DX',
	'DY',
	'EA',
	'EB',
	'EC',
	'ED',
	'EE',
	'EF',
	'EG',
	'EH',
	'FA',
	'FB',
	'FC',
	'FD',
	'FE',
	'FF',
	'FG',
	'FH',
	'FI',
	'GA',
	'GB',
	'GC',
	'GD',
	'GF',
	'GG',
	'HA',
	'HB',
	'HC',
	'HD',
	'HE',
	'HF',
	'HG',
	'HH',
	'HI',
	'HJ',
	'HK',
	'HL',
	'HM',
	'HN',
	'IA',
	'IB',
	'IC',
	'ID',
	'IE',
	'IF',
	'IG',
	'JA',
	'JB',
	'JC',
	'JD',
	'JE',
	'JF',
	'JG',
	'JX',
	'KA',
	'KB',
	'KD',
	'KG',
	'KH',
	'KK',
	'KL',
	'LA',
	'LB',
	'LC',
	'LD',
	'LE',
	'LH',
	'LI',
	'MA',
	'MB',
	'MC',
	'MD',
	'ME',
	'MF',
	'MG',
	'MH',
	'MI',
	'MJ',
	'MK',
	'ML',
	'MM',
	'MN',
	'NA',
	'NB',
	'NC',
	'ND',
	'NF',
	'OA',
	'OB',
	'OC',
	'OD',
	'OF',
	'OG',
	'OH',
	'OI',
	'OJ',
	'OK',
	'OL',
	'PA',
	'PB',
	'PC',
	'PD',
	'PE',
	'PF',
	'PG',
	'PH',
	'PI',
	'PJ',
	'PK',
	'PL',
	'PM',
	'PN',
	'PO',
	'PP',
	'PQ',
	'PR',
	'PS',
	'QA',
	'QB',
	'QC',
	'QD',
	'QE',
	'QF',
	'QG',
	'QH',
	'QJ',
	'QL',
	'QM',
	'QN',
	'QO',
	'QP',
	'RA',
	'RB',
	'RC',
	'RD',
	'RE',
	'RF',
	'RG',
	'RH',
	'RJ',
	'RK',
	'RL',
	'RM',
	'RN',
	'RO',
	'RP',
	'RQ',
	'RR',
	'RS',
	'SA',
	'SB',
	'SC',
	'SD',
	'SE',
	'SF',
	'SG',
	'TA',
	'TB',
	'TC',
	'TD',
	'UA',
	'UB',
	'UC',
	'UD',
	'UE',
	'UF',
	'UG',
	'UH',
	'UI',
	'UJ',
	'UK',
	'VA',
	'VB',
	'VC',
	'VD',
	'VE',
	'VF',
	'VG',
	'VH',
	'VI',
	'VJ',
	'VK',
	'WA',
	'WB',
	'WC',
	'WD',
	'WE',
	'WF',
	'WG',
	'WH',
	'WI',
	'WJ',
	'WK',
	'WL',
	'WM',
	'WN',
	'WO',
	'WP',
	'WQ',
	'XD',
	'XE',
	'XF',
	'XG',
	'XH',
	'XI',
	'XL',
	'XO',
	'XP',
	'XS',
	'XT',
	'XU',
]);
export const allSubGroupsSet = arrayToHashset(allSubGroups);
export type SubGroup = keyof typeof SubGroups;

export const allCombinationSet = arrayToHashset([...allGroups, ...allSubGroups]);
export const subGroupsByGroups = allSubGroups.reduce((acc, subGroup) => {
	const group = getGroup(subGroup)!;
	let subGroups = acc[group];
	if (subGroups == null) {
		subGroups = new Set();
		acc[group] = subGroups;
	}
	subGroups.add(subGroup);
	return acc;
}, {} as { [k in Group]: Set<SubGroup> });

export const groupNames: { [k in Group]: TranslatedString } = {
	'1': { nl: 'Wandkoeling', fr: 'Refroidissement mural' },
	'3': { nl: 'AGE', fr: 'Fruits legumes pdt' },
	'5': { nl: 'Diepvries', fr: 'Surgelés' },
	'6': { nl: 'Kaas.bediening', fr: 'Fromages' },
	'7': { nl: 'Bakkerij', fr: 'Boulangerie' },
	'8': { nl: 'Vis', fr: 'Poisson' },
	'B': { nl: 'Rookwaren', fr: 'Tabac' },
	'C': { nl: 'Nonfood', fr: 'Non-alimentaire' },
	'D': { nl: 'Bieren en waters', fr: 'Bières/eaux' },
	'E': { nl: 'Melk suiker eieren', fr: 'Lait/sucre/oeufs' },
	'F': { nl: 'Papier', fr: 'Papier' },
	'G': { nl: 'Wasmiddelen', fr: 'Detergents' },
	'H': { nl: 'Onderhoudsartikelen', fr: 'Entretien' },
	'I': { nl: 'Diervoeding', fr: 'Alimentation animale' },
	'J': { nl: 'Dieet/natuur', fr: 'Diététique/nature' },
	'K': { nl: 'Gr/fruit/soep cons', fr: 'Légumes/fruits/soupes cons.' },
	'L': { nl: 'Cons vis/vlees/maal', fr: 'Cons. poisson/viandes/plats prép' },
	'M': { nl: 'Kruiderij vetten', fr: 'Condiments/huiles' },
	'N': { nl: 'Meel/rijst/deegware', fr: 'Farine/riz/pâtes' },
	'O': { nl: 'Babyvoeding', fr: 'Alimentation bébé' },
	'P': { nl: 'Broodbeleg/ontbijt', fr: 'Garnitures pain/p. déjeuner' },
	'Q': { nl: 'Wijnen', fr: 'Vins' },
	'R': { nl: 'Alcohol/aperitief', fr: 'Alcool/apéritif' },
	'S': { nl: 'Chips/snacks', fr: 'Chips/snacks' },
	'T': { nl: 'Confiserie', fr: 'Confiserie' },
	'U': { nl: 'Koekjes', fr: 'Biscuits' },
	'V': { nl: 'Warme dranken', fr: 'Boissons chaudes' },
	'W': { nl: 'Persoonlijke verz', fr: 'Soins personnels' },
	'X': { nl: 'Speciale artikelen', fr: 'Articles spéciaux' },
};

export const subGroupNames: { [k in SubGroup]: TranslatedString } = {
	'1*': { nl: 'Vleesvervangers', fr: 'Substituts de viande' },
	'1+': { nl: 'Dips & spreads', fr: 'Dips & spreads' },
	'10': { nl: 'Ind kaas gouda', fr: 'Fromage individuel gouda' },
	'11': { nl: 'Ind harde kazen', fr: 'Fromage individuel à pâte dure' },
	'12': { nl: 'Ind halfharde', fr: 'Fromage individuel à pâte mi-dure' },
	'13': { nl: 'Ind witschimmel', fr: 'Fromage individuel à pâte molle' },
	'14': { nl: 'Ind geaderd schimm', fr: 'Fromage individuel à pâte persillée' },
	'15': { nl: 'Ind roodbacterie', fr: 'Fromage individuel à pâte lavée' },
	'16': { nl: 'Ind geit/schaap', fr: 'Fromage individuel chèvre/brebis' },
	'17': { nl: 'Ind kaas gemalen', fr: 'Fromage individuel râpé' },
	'18': { nl: 'Ind diverse kazen', fr: 'Fromage individuel divers' },
	'19': { nl: 'Ind witte kaas', fr: 'Fromage individuel blanc' },
	'1A': { nl: 'Ind verse kaas', fr: 'Fromage individuel frais' },
	'1B': { nl: 'Ind kaas apero', fr: 'Fromage individuel pour apéritif' },
	'1C': { nl: 'Char ind gek ham', fr: 'Charcuterie individuelle jambon cuit' },
	'1D': { nl: 'Char ind kookwaren', fr: 'Charcuterie individuelle charcuterie cuite' },
	'1E': { nl: 'Char ind zoutwaren', fr: 'Charcuterie individuelle charcuterie salée' },
	'1F': { nl: 'Char ind salami', fr: 'Charcuterie individuelle salami' },
	'1G': { nl: 'Char ind apero', fr: 'Charcuterie individuelle apéritif' },
	'1H': { nl: 'Char ind gevogelte', fr: 'Charcuterie individuelle volaille' },
	'1I': { nl: 'Char ind pate', fr: 'Charcuterie individuelle pâté' },
	'1J': { nl: 'Char ind kop/aspic', fr: 'Charcuterie individuelle tête/pressé' },
	'1K': { nl: 'Char ind freshpack', fr: 'Char ind freshpack' },
	'1L': { nl: 'Char ind salades', fr: 'Charcuterie individuelle salades' },
	'1M': { nl: 'Trait ind ber maalt', fr: 'Traiteur individuel plat préparé' },
	'1N': { nl: 'Trait ind component', fr: 'Traiteur individuel composant' },
	'1O': { nl: 'Olijven/tapenades', fr: 'Olives/tapenades' },
	'1P': { nl: 'Tr ind soep/saus', fr: 'Traiteur individuel soupe/sauce' },
	'1Q': { nl: 'Trait ind aperitief', fr: 'Traiteur individuel apéritif' },
	'1R': { nl: 'Trait ind snacks', fr: 'Traiteur individuel snacks' },
	'1S': { nl: 'Margarine', fr: 'Margarine' },
	'1T': { nl: 'Boter', fr: 'Beurre' },
	'1U': { nl: 'Ultra verse drinks', fr: 'Boissons ultra fraîches' },
	'1V': { nl: 'Verse kaas', fr: 'Fromage frais' },
	'1W': { nl: 'Yoghurt', fr: 'Yaourt' },
	'1X': { nl: 'Dessert', fr: 'Dessert' },
	'1Y': { nl: 'Room', fr: 'Crème' },
	'3A': { nl: 'Fruit', fr: 'Fruits' },
	'3B': { nl: 'Groenten', fr: 'Legumes' },
	'3C': { nl: 'Aardappel / ajuin', fr: 'Pdt / oignons' },
	'3D': { nl: '4° gamma', fr: '4° gamme' },
	'3G': { nl: 'Agf algemeen', fr: 'Agf general' },
	'3H': { nl: 'Bloemen / planten', fr: 'Fleurs / plantes' },
	'5A': { nl: 'Aardappelproducten', fr: 'Produits à base de pomme de terre' },
	'5B': { nl: 'Schepijs', fr: 'Glace à la cuillère' },
	'5C': { nl: 'Ijs multipacks', fr: 'Packs de glaces' },
	'5D': { nl: 'Diverse ijs', fr: 'Divers glaces' },
	'5E': { nl: 'Groenten', fr: 'Légumes' },
	'5G': { nl: 'Fruit', fr: 'Fruits' },
	'5H': { nl: 'Soep', fr: 'Potages' },
	'5I': { nl: 'Bereide gerechten', fr: 'Plats préparés' },
	'5J': { nl: 'Vis', fr: 'Poisson' },
	'5K': { nl: 'Snacks diepvries', fr: 'Snacks surgelés' },
	'5L': { nl: 'Vegetarisch diepvr', fr: 'Végétarien surgelé' },
	'5N': { nl: 'Brood viennoiserie', fr: 'Pain viennoiserie' },
	'5P': { nl: 'Vlees / Gevogelte', fr: 'Viande / Volaille' },
	'6H': { nl: 'Diverse kazen', fr: 'Fromages divers' },
	'7A': { nl: 'Dagvers brood', fr: 'Pain frais' },
	'7B': { nl: 'Dagvers broodjes', fr: 'Petits pains frais' },
	'7C': { nl: 'Dagvers patisserie', fr: 'Patisserie fraiche' },
	'7D': { nl: 'Dagvers snacks', fr: 'Snacks frais' },
	'7E': { nl: 'Dagvers stokbrood', fr: 'Baguettes frais' },
	'7F': { nl: 'Dagvers viennoiseri', fr: 'Viennoiserie frais' },
	'7H': { nl: 'Bake off brood', fr: 'Bake off pain' },
	'7I': { nl: 'Bake off broodjes', fr: 'Petits pains à cuire' },
	'7J': { nl: 'Bake off patisserie', fr: 'Bake off patisserie' },
	'7K': { nl: 'Bake off snacks', fr: 'Bake off snacks' },
	'7L': { nl: 'Bake off stokbrood', fr: 'Bake off baguette' },
	'7M': { nl: 'Bake off viennoiser', fr: 'Bake off viennoiser' },
	'7N': { nl: 'Bake off americ bak', fr: 'Bake off americ bak' },
	'7O': { nl: 'Industrieel brood', fr: 'Pain industriel' },
	'7P': { nl: 'Rijskasten cru', fr: 'Armoire a lever cru' },
	'7Q': { nl: 'Industr koeling', fr: 'Réfrigération industrielle' },
	'7S': { nl: 'Impuls bakkerij', fr: 'Boulangerie impulsion' },
	'8A': { nl: 'Verse vis', fr: 'Poisson frais' },
	'8B': { nl: 'Gerookte vis', fr: 'Poisson fumé' },
	'8C': { nl: 'Visbereidingen', fr: 'Prepar. de poisson' },
	'8D': { nl: 'Garnalen', fr: 'Crevettes' },
	'8E': { nl: 'Schaal/scheldier', fr: 'Mollusques/crustace' },
	'8F': { nl: 'Opgelegde vis', fr: 'Poisson en conserve' },
	'8G': { nl: 'Vis diverse', fr: 'Poisson divers' },
	'8I': { nl: 'Apero vis', fr: 'Apéritif de poisson' },
	'BA': { nl: 'Ryo tabak', fr: 'Ryo tabac' },
	'BB': { nl: 'Myo tabak', fr: 'Myo tabac' },
	'BC': { nl: 'E-sigaret', fr: 'E-cigarette' },
	'BD': { nl: 'Sigaret', fr: 'Cigarette' },
	'BE': { nl: 'Sigaar', fr: 'Cigare' },
	'BF': { nl: 'Cigarillo', fr: 'Cigarillo' },
	'BG': { nl: 'Toebehoren', fr: 'Accessoires' },
	'BH': { nl: 'Rookwaren diverse', fr: 'Prod fumeurs divers' },
	'CA': { nl: 'Koken/tafelen', fr: 'Cuisine/table' },
	'CB': { nl: 'Speelgoed', fr: 'Jouets' },
	'CC': { nl: 'Schrijfwaren', fr: 'Fournitures de bureau' },
	'CD': { nl: 'Huishoud onderhoud', fr: 'Entretien ménager' },
	'CE': { nl: 'Naaibenodigdheden', fr: 'Mercerie' },
	'CF': { nl: 'Baby-accessoires', fr: 'Accessoires bebe' },
	'CG': { nl: 'Acc pers verzorging', fr: 'Acc soin personnel' },
	'CH': { nl: 'Kaarsen', fr: 'Bougies' },
	'CI': { nl: 'Feestartikelen', fr: 'Articles party' },
	'CJ': { nl: 'Petshop', fr: 'Animalerie' },
	'CK': { nl: 'Do-it-yourself', fr: 'Faites-le vous-même' },
	'CM': { nl: 'Tuinonderhoud', fr: 'Entretien du jardin' },
	'CN': { nl: 'Bbq/aanmaakproduct', fr: 'Bbq/produits d\'allumage' },
	'CO': { nl: 'Lampen/batterijen', fr: 'Lampes/batteries' },
	'CP': { nl: 'Multimedia', fr: 'Multimedia' },
	'CQ': { nl: 'Wenskaarten', fr: 'Cartes de voeux' },
	'CR': { nl: 'Services/toebehoren', fr: 'Services' },
	'CS': { nl: 'E-vouchers', fr: 'E-vouchers' },
	'CT': { nl: 'Pers/lectuur', fr: 'Presse/livres' },
	'CX': { nl: 'Textiel/schoenen', fr: 'Textile/chaussures' },
	'CY': { nl: 'Spaaracties nonfood', fr: 'Act d\'epargnes (nf)' },
	'DA': { nl: 'Pilsbieren', fr: 'Bières pils' },
	'DB': { nl: 'Tafelbieren', fr: 'Bières de table' },
	'DC': { nl: 'Witbieren', fr: 'Bières blanches' },
	'DD': { nl: 'Amberbieren', fr: 'Bières ambrées' },
	'DE': { nl: 'Fruitbieren', fr: 'Bières fruitées' },
	'DF': { nl: 'Ale bieren', fr: 'Bières ale' },
	'DG': { nl: 'Sterke bieren', fr: 'Bières fortes' },
	'DH': { nl: 'Abdijbieren', fr: 'Bières d\'abbaye' },
	'DI': { nl: 'Trappistenbieren', fr: 'Bières trappistes' },
	'DJ': { nl: 'Sterk bruine bieren', fr: 'Bieres brunes forte' },
	'DK': { nl: 'Fruitsappen', fr: 'Jus de fruits' },
	'DL': { nl: 'Functionele dranken', fr: 'Boissons fonctionnelles' },
	'DM': { nl: 'Bieren >=75cl', fr: 'Bieres >=75cl' },
	'DN': { nl: 'Siropen', fr: 'Sirops' },
	'DO': { nl: 'Tonic & bitters', fr: 'Tonic & bitters' },
	'DP': { nl: 'Limonade niet bruis', fr: 'Limonade non gazeuse' },
	'DQ': { nl: 'Limonade bruisend', fr: 'Limonade gazeuse' },
	'DR': { nl: 'Cola', fr: 'Cola' },
	'DS': { nl: 'Ice tea', fr: 'Thé glacé' },
	'DT': { nl: 'Sportdrank', fr: 'Boissons sportives' },
	'DU': { nl: 'Energydrank', fr: 'Boissons énergétiques' },
	'DV': { nl: 'Water niet bruisend', fr: 'Eau non gazeuse' },
	'DW': { nl: 'Water lichtbruisend', fr: 'Eau légèrement gazeuse' },
	'DX': { nl: 'Water bruisend', fr: 'Eau gazeuse' },
	'DY': { nl: 'Water gearomatis', fr: 'Eau aromatisée' },
	'EA': { nl: 'Witte melk', fr: 'Lait blanc' },
	'EB': { nl: 'Chocomelk/fruitmelk', fr: 'Chocolat au lait / lait fruité' },
	'EC': { nl: 'Plantaardige melk', fr: 'Lait végétal' },
	'ED': { nl: 'Eieren', fr: 'Œufs' },
	'EE': { nl: 'Melkproducten diver', fr: 'Produits laitiers divers' },
	'EF': { nl: 'Melkdessert', fr: 'Desserts lactés' },
	'EG': { nl: 'Suiker', fr: 'Sucre' },
	'EH': { nl: 'Zoetstoffen', fr: 'Édulcorants' },
	'FA': { nl: 'Intieme hygiene', fr: 'Hygiène intime' },
	'FB': { nl: 'Toiletpapier', fr: 'Papier toilette' },
	'FC': { nl: 'Baby', fr: 'Bébé' },
	'FD': { nl: 'Zakdoekjes', fr: 'Mouchoirs' },
	'FE': { nl: 'Keukenrol', fr: 'Essuie-tout' },
	'FF': { nl: 'Servet/tafellaken', fr: 'Serviettes/nappes' },
	'FG': { nl: 'Alu/folie/plastiek', fr: 'Aluminium/feuille/plastique' },
	'FH': { nl: 'Disposables', fr: 'Jetables' },
	'FI': { nl: 'Brilpoetsdoekjes', fr: 'Lingettes nettoyantes pour lunettes' },
	'GA': { nl: 'Waspoeder', fr: 'Poudre à laver' },
	'GB': { nl: 'Vloeibaar wasmiddel', fr: 'Detergent liquide' },
	'GC': { nl: 'Wasverzachter', fr: 'Assouplisseur' },
	'GD': { nl: 'Voorgedoseerd', fr: 'Prédosé' },
	'GF': { nl: 'Gedemineralis water', fr: 'Eau déminéralisée' },
	'GG': { nl: 'Wasadditief', fr: 'Additif de lavage' },
	'HA': { nl: 'Machinevaat', fr: 'Lave-vaisselle machine' },
	'HB': { nl: 'Handvaat', fr: 'Lavage à la main' },
	'HC': { nl: 'Bleekwater', fr: 'Eau de javel' },
	'HD': { nl: 'Zachte zeep', fr: 'Savon doux' },
	'HE': { nl: 'Speciale reinigers', fr: 'Nettoyants spéciaux' },
	'HF': { nl: 'Allesreiniger', fr: 'Nettoyant tout usage' },
	'HG': { nl: 'Schuurproducten', fr: 'Produits à récurer' },
	'HH': { nl: 'Ruitenreiniger', fr: 'Nettoyant pour vitres' },
	'HI': { nl: 'Wc onderhoud', fr: 'Entretien WC' },
	'HJ': { nl: 'Luchtverfrisser', fr: 'Désodorisant' },
	'HK': { nl: 'Div onderhoudsprod', fr: 'Divers produits d\'entretien' },
	'HL': { nl: 'Keuken/badreiniger', fr: 'Nettoyant cuisine/salle de bain' },
	'HM': { nl: 'Reinigingsdoekjes', fr: 'Lingettes nettoyantes' },
	'HN': { nl: 'Schoenonderhoud', fr: 'Entretien des chaussures' },
	'IA': { nl: 'Kattenvoeding nat', fr: 'Nourriture humide pour chats' },
	'IB': { nl: 'Kattenvoeding droog', fr: 'Nourriture sèche pour chats' },
	'IC': { nl: 'Kattenvoeding snack', fr: 'Friandises pour chats' },
	'ID': { nl: 'Kattenbakvulling', fr: 'Litière pour chats' },
	'IE': { nl: 'Hondenvoeding nat', fr: 'Nourriture humide pour chiens' },
	'IF': { nl: 'Hondenvoeding droog', fr: 'Nourriture sèche pour chiens' },
	'IG': { nl: 'Hondenvoeding snack', fr: 'Friandises pour chiens' },
	'JA': { nl: 'Bewust tussendoortj', fr: 'En-cas responsable' },
	'JB': { nl: 'Slimming', fr: 'Nutrition minceur' },
	'JC': { nl: 'Sport & performance', fr: 'Sport & performance' },
	'JD': { nl: 'Suikerbewust', fr: 'Conscient du sucre' },
	'JE': { nl: 'Glutenvrij', fr: 'Sans gluten' },
	'JF': { nl: 'Lactosearm', fr: 'Sans lactose' },
	'JG': { nl: 'Gezondheid', fr: 'Santé' },
	'JX': { nl: 'Toets voeding', fr: 'Touche aliment' },
	'KA': { nl: 'Tomaten', fr: 'Tomates' },
	'KB': { nl: 'Groenten', fr: 'Légumes' },
	'KD': { nl: 'Bonen/Peulvruchten', fr: 'Haricots/Légumineuses' },
	'KG': { nl: 'Fruitconserven', fr: 'Conserves de fruits' },
	'KH': { nl: 'Compote', fr: 'Compote' },
	'KK': { nl: 'Soepen Wet', fr: 'Soupes Humides' },
	'KL': { nl: 'Soepen Dry', fr: 'Soupes Sèches' },
	'LA': { nl: 'Ber Maaltijden Wet', fr: 'Plats Préparés Humides' },
	'LB': { nl: 'Ber Maaltijden Dry', fr: 'Plats Préparés Sèches' },
	'LC': { nl: 'Wereldkeuken', fr: 'Cuisines du monde' },
	'LD': { nl: 'Aardappuree/Bereid', fr: 'Purée de pommes de terre/Préparées' },
	'LE': { nl: 'Mediterraanse Spec', fr: 'Spécialités Méditerranéennes' },
	'LH': { nl: 'Vleesconserven', fr: 'Conserves de viande' },
	'LI': { nl: 'Visconserven', fr: 'Conserves de poisson' },
	'MA': { nl: 'Azijn', fr: 'Vinaigre' },
	'MB': { nl: 'Olie', fr: 'Huile' },
	'MC': { nl: '(Frituur)Vet', fr: 'Huile (de friture)' },
	'MD': { nl: 'Ajuin/Augurk/Kapper', fr: 'Oignon/Cornichon/Câpre' },
	'ME': { nl: 'Mosterd', fr: 'Moutarde' },
	'MF': { nl: 'Pickels', fr: 'Pickles' },
	'MG': { nl: 'Mayonaise', fr: 'Mayonnaise' },
	'MH': { nl: 'Koude Sauzen', fr: 'Sauces froides' },
	'MI': { nl: 'Warme Saus Vloeibaa', fr: 'Sauce Chaude Liquide' },
	'MJ': { nl: 'Warme Droge Saus', fr: 'Sauce Sèche Chaude' },
	'MK': { nl: 'Kruid/Zout/Specerij', fr: 'Herbe/Sel/Épices' },
	'ML': { nl: 'Smaakmakers', fr: 'Exhausteurs de goût' },
	'MM': { nl: 'Vinaigrette', fr: 'Vinaigrette' },
	'MN': { nl: 'Warme Pastasaus', fr: 'Sauce Pasta Chaude' },
	'NA': { nl: 'Pudding', fr: 'Pudding' },
	'NB': { nl: 'Ijsafwerking', fr: 'Finitions de glace' },
	'NC': { nl: 'Rijst/Rijstprod', fr: 'Riz/Produits de riz' },
	'ND': { nl: 'Pasta', fr: 'Pâtes' },
	'NF': { nl: 'Bloem/Bakmeel/Dr Vr', fr: 'Farine/Farine à gâteau/Produits séchés' },
	'OA': { nl: 'Babykoeken', fr: 'Biscuits pour bébés' },
	'OB': { nl: 'Maaltijd in Glas', fr: 'Repas en verre' },
	'OC': { nl: 'Maaltijd in cup', fr: 'Repas cup' },
	'OD': { nl: 'Maaltijd in Bord', fr: 'Repas dans une assiette' },
	'OF': { nl: 'Dessert', fr: 'Dessert' },
	'OG': { nl: 'Drinks', fr: 'Boissons' },
	'OH': { nl: 'Meelprodukten', fr: 'Produits de farine' },
	'OI': { nl: 'Fruit in Glas', fr: 'Fruits en verre' },
	'OJ': { nl: 'Fruit in Cup', fr: 'Fruits en coupe' },
	'OK': { nl: 'Fruit in Pouch', fr: 'Fruits en pochette' },
	'OL': { nl: 'Tussendoortjes', fr: 'Collations' },
	'PA': { nl: 'Smeerpasta', fr: 'Pâte à tartiner' },
	'PB': { nl: 'Hagelslag', fr: 'Vermicelles à tartiner' },
	'PC': { nl: 'Confituur', fr: 'Confiture' },
	'PD': { nl: 'Siroop', fr: 'Sirop' },
	'PE': { nl: 'Honing', fr: 'Miel' },
	'PF': { nl: 'Smeerkaas', fr: 'Fromage à tartiner' },
	'PG': { nl: 'Smeerkaas Apero', fr: 'Fromage à tartiner Apéro' },
	'PH': { nl: 'Ontbijtgranen Kids', fr: 'Céréales pour enfants' },
	'PI': { nl: 'Ontbijtgranen Volw', fr: 'Céréales pour adultes' },
	'PJ': { nl: 'Ontbijtgr Classic', fr: 'Céréales Petit Déjeuner Classique' },
	'PK': { nl: 'Ontbijtgr Teenager', fr: 'Céréales Petit Déjeuner Adolescent' },
	'PL': { nl: 'Muesli', fr: 'Muesli' },
	'PM': { nl: 'Havermout', fr: 'Flocons d\'avoine' },
	'PN': { nl: 'Cruesli', fr: 'Cruesli' },
	'PO': { nl: 'Beschuit', fr: 'Biscuits' },
	'PP': { nl: 'Geroosterd Brood', fr: 'Pain Grillé' },
	'PQ': { nl: 'Toast', fr: 'Toast' },
	'PR': { nl: 'Knackebrood', fr: 'Pain croustillant' },
	'PS': { nl: 'Rijstwafel', fr: 'Galette de riz' },
	'QA': { nl: 'Frankrijk Rood', fr: 'France Rouge' },
	'QB': { nl: 'Europa Rood', fr: 'Europe Rouge' },
	'QC': { nl: 'Nieuwe Wereld Rood', fr: 'Nouveau Monde Rouge' },
	'QD': { nl: 'Frankrijk Wit', fr: 'France Blanc' },
	'QE': { nl: 'Europa Wit', fr: 'Europe Blanc' },
	'QF': { nl: 'Nieuwe Wereld Wit', fr: 'Nouveau Monde Blanc' },
	'QG': { nl: 'Rose Wijnen', fr: 'Vins Rosés' },
	'QH': { nl: 'Schuimwijnen', fr: 'Vins Mousseux' },
	'QJ': { nl: 'Party-Box (Bib)', fr: 'Boîte de Fête (BIB)' },
	'QL': { nl: 'Alcoholvrij', fr: 'Sans alcool' },
	'QM': { nl: 'Toets wijn', fr: 'Sélection de vins' },
	'QN': { nl: 'Frankrijk rose', fr: 'France rose' },
	'QO': { nl: 'Europa rose', fr: 'Europe rose' },
	'QP': { nl: 'Nieuwe wereld rose', fr: 'Rose nouveau monde' },
	'RA': { nl: 'Porto', fr: 'Porto' },
	'RB': { nl: 'Sherry', fr: 'Xérès' },
	'RC': { nl: 'Vermouth', fr: 'Vermouth' },
	'RD': { nl: 'Bitter', fr: 'Amer' },
	'RE': { nl: 'Cocktails', fr: 'Cocktails' },
	'RF': { nl: 'Dessertwijnen', fr: 'Vins Dessert' },
	'RG': { nl: 'Aperitieven Divers', fr: 'Apéritifs Divers' },
	'RH': { nl: 'Aperitief Alcovrij', fr: 'Apéritif sans alcool' },
	'RJ': { nl: 'Whisky', fr: 'Whisky' },
	'RK': { nl: 'Vodka', fr: 'Vodka' },
	'RL': { nl: 'Rum', fr: 'Rhum' },
	'RM': { nl: 'Gin', fr: 'Gin' },
	'RN': { nl: 'Tequila', fr: 'Téquila' },
	'RO': { nl: 'Jenever', fr: 'Genièvre' },
	'RP': { nl: 'Cogn/Brandy/Armagn', fr: 'Cognac/Brandy/Armagnac' },
	'RQ': { nl: 'Likeur', fr: 'Liqueur' },
	'RR': { nl: 'Anijs', fr: 'Anis' },
	'RS': { nl: 'Sterke Drank Divers', fr: 'Spiritueux Divers' },
	'SA': { nl: 'Chips', fr: 'Chips' },
	'SB': { nl: 'Extruded Chips', fr: 'Chips Extrudés' },
	'SC': { nl: 'Tortilla', fr: 'Tortilla' },
	'SD': { nl: 'Zoute Snacks', fr: 'Snacks Salés' },
	'SE': { nl: 'Flavoured Snacks', fr: 'Snacks Aromatisés' },
	'SF': { nl: 'Diverse Snacks', fr: 'Snacks Divers' },
	'SG': { nl: 'Noten/Droge Vrucht', fr: 'Noix/Fruits Secs' },
	'TA': { nl: 'Chocolade', fr: 'Chocolat' },
	'TB': { nl: 'Confiserie Kassa', fr: 'Confiserie de Caisse' },
	'TC': { nl: 'Chobisco Seizoen', fr: 'Chocolat de Saison' },
	'TD': { nl: 'Snoep', fr: 'Bonbons' },
	'UA': { nl: 'Koeken Kids', fr: 'Biscuits pour Enfants' },
	'UB': { nl: 'Droge Koeken', fr: 'Biscuits Secs' },
	'UC': { nl: 'Chocoladekoeken', fr: 'Biscuits au Chocolat' },
	'UD': { nl: 'Gevulde Koeken', fr: 'Biscuits Fourrés' },
	'UE': { nl: 'Speculoos', fr: 'Spéculoos' },
	'UF': { nl: 'Koeken Guests', fr: 'Biscuits pour Invités' },
	'UG': { nl: 'Cake/Gebak', fr: 'Gâteaux/Pâtisseries' },
	'UH': { nl: 'Wafels/Galetten', fr: 'Gaufres/Galettes' },
	'UI': { nl: 'Ijswafels', fr: 'Gaufrettes Glacées' },
	'UJ': { nl: 'Peperkoek', fr: 'Pain d\'Épice' },
	'UK': { nl: 'Koeken Nutritioneel', fr: 'Biscuits Nutritionnels' },
	'VA': { nl: 'Koffiebonen', fr: 'Grains de Café' },
	'VB': { nl: 'Gemalen Koffie', fr: 'Café Moulu' },
	'VC': { nl: 'Oploskoffie', fr: 'Café Soluble' },
	'VD': { nl: 'Koffiefilters', fr: 'Filtres à Café' },
	'VE': { nl: 'Chicorei', fr: 'Chicorée' },
	'VF': { nl: 'Koffiepads', fr: 'Dossettes de Café' },
	'VG': { nl: 'Thee', fr: 'Thé' },
	'VH': { nl: 'Koffiepods', fr: 'Capsules de Café' },
	'VI': { nl: 'Chocoladedranken', fr: 'Boissons au Chocolat' },
	'VJ': { nl: 'Filterpapier', fr: 'Filtres à Café' },
	'VK': { nl: 'Ontkalker', fr: 'Détartrant' },
	'WA': { nl: 'Toiletzeep', fr: 'Savon de Toilette' },
	'WB': { nl: 'Tandpasta/-Verzorg', fr: 'Soins Dentaires' },
	'WC': { nl: 'Tandenborstel', fr: 'Brosse à Dents' },
	'WD': { nl: 'Bad', fr: 'Bain' },
	'WE': { nl: 'Deodorant', fr: 'Déodorant' },
	'WF': { nl: 'Douche', fr: 'Douche' },
	'WG': { nl: 'Shampoo', fr: 'Shampooing' },
	'WH': { nl: 'Conditioner', fr: 'Après-Shampooing' },
	'WI': { nl: 'Stylingproduct', fr: 'Produits de Coiffure' },
	'WJ': { nl: 'Haarkleuring', fr: 'Coloration Capillaire' },
	'WK': { nl: 'Scheerproducten', fr: 'Produits de Rasage' },
	'WL': { nl: 'Zonneproducten', fr: 'Produits solaires' },
	'WM': { nl: 'Gelaatsverzorging', fr: 'Soins du Visage' },
	'WN': { nl: 'Lichaamsverzorging', fr: 'Soins Corporels' },
	'WO': { nl: 'Ehbo', fr: 'Premiers secours' },
	'WP': { nl: 'Voet/Nagelverzorg', fr: 'Soins des Pieds/des Ongles' },
	'WQ': { nl: 'Kinderverzorging', fr: 'Soins pour Enfants' },
	'XD': { nl: 'Marketing', fr: 'Marketing' },
	'XE': { nl: 'Actie artikel fabr', fr: 'Actie artikel fabr' },
	'XF': { nl: 'Onkosten verpakking', fr: 'Onkosten verpakking' },
	'XG': { nl: 'Correctieartikelen', fr: 'Correctieartikelen' },
	'XH': { nl: 'Leeggoed', fr: 'Vidange' },
	'XI': { nl: 'Diverse onkosten', fr: 'Diverse onkosten' },
	'XL': { nl: 'Toeslagen/kortingen', fr: 'Surtaxes/rabais' },
	'XO': { nl: 'Onbekend', fr: 'Inconnu' },
	'XP': { nl: 'Herbruikbare Tas', fr: 'Sac Réutilisable' },
	'XS': { nl: 'Lotto+', fr: 'Lotto+' },
	'XT': { nl: 'Commerciele dienst', fr: 'Services commercial' },
	'XU': { nl: 'Vuilniszakken gemee', fr: 'Sac poubelle comm' },
};
